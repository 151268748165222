import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import authentic from "../assets/auth.svg";
import box from "../assets/icons/ESCOBOXWHITE.svg";
import filter from "../assets/icons/FILTROWHITE.svg";
import itinerary from "../assets/icons/ITINERARIOWHITE.svg";
import magicGreen from "../assets/icons/MAGICGREEN.svg";
import map from "../assets/icons/MAPPAWHITE.svg";
import people from "../assets/icons/PEOPLEWHITE.svg";
import Footer from "../components/Footer";
import NavbarCreator from "../components/NavbarCreator";
import { SingleFaq } from "./BecomePartner";

export function TableComparison() {
  return (
    <div className="relative border-4 border-primary rounded-[40px] md:rounded-[50px] mt-12 md:mt-36 overflow-scroll">
      <div className="grid grid-cols-5 min-w-[450px]">
        <div className="col-span-2" />
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tl-[60px] hidden md:flex">
          <p className="font-bold text-white md:text-xl">esco.zone</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tl-[30px] md:hidden">
          <p className="font-bold text-white md:text-xl">esco .zone</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary">
          <p className="font-bold text-white md:text-xl">Online Adv</p>
        </div>
        <div className="flex items-center justify-center px-8 py-8 md:py-12 bg-primary rounded-tr-[30px] md:rounded-tr-[40px]">
          <p className="font-bold text-white md:text-xl">Offline Adv</p>
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t-2 border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈 Conoscenze tecniche richieste
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:px-8 md:text-2xl border-primary">
          limitate
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:px-8 md:text-2xl border-primary">
          elevate
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border border-r-0 md:py-12 md:px-8 md:text-2xl border-primary">
          moderate
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈 Struttura dei costi
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          commissione
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          variabili
        </div>
        <div className="flex items-center justify-center px-4 py-8 border border-r-0 md:px-8 md:py-12 md:text-2xl border-primary">
          elevati
        </div>
        <div className="col-span-2 px-4 py-8 font-bold border-t border-b border-r md:py-12 md:px-8 md:text-2xl border-primary">
          📈 Tempo per mettere in mostra la tua attività
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          ridotto
        </div>
        <div className="flex items-center justify-center px-4 py-8 text-sm border md:py-12 md:text-2xl md:px-8 border-primary">
          elevato
        </div>
        <div className="flex items-center justify-center px-4 py-8 border border-r-0 md:px-8 md:py-12 md:text-2xl border-primary">
          elevato
        </div>
        <div className="col-span-2 px-4 py-12 font-bold border-t border-r md:px-8 md:text-2xl border-primary">
          📈 Targeting
        </div>
        <div className="flex items-center justify-center px-4 py-12 text-sm border border-b-0 md:text-2xl md:px-8 border-primary">
          mirato
        </div>
        <div className="flex items-center justify-center px-4 py-12 text-sm border border-b-0 md:text-2xl md:px-8 border-primary">
          limitato
        </div>
        <div className="flex items-center justify-center px-4 py-12 border border-b-0 border-r-0 md:px-8 md:text-2xl border-primary">
          generico
        </div>
      </div>
    </div>
  );
}

export function CentralBecomePartnerMessage() {
  return (
    <>
      <div className="hidden grid-cols-1 mt-36 gap-x-6 md:grid-cols-3 md:grid">
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Local</p>
          <p className="text-3xl font-thin md:text-4xl">
            il luogo perfetto per fare emergere le piccole realtà locali
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Su misura</p>
          <p className="text-3xl font-thin md:text-4xl">
            grazie alle diverse categorie si adatta a diverse soluzioni
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Authentic</p>
          <p className="text-3xl font-thin md:text-4xl">
            in linea con le nuove tendenze di viaggio esperienziale
          </p>
        </div>
        {/* ----- vertical lines ----- */}
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        {/*  ----- dots with icon ------ */}
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={map} alt="local" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={filter} alt="su misura" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="relative flex items-center justify-center w-full h-12">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img
              src={authentic}
              alt="authentic"
              className="w-12 h-12 mx-auto"
            />
          </div>
        </div>
        {/* ----central message ----- */}
        <div className="-mt-6 flex px-44 py-56 items-center justify-center text-center border-4 border-primary rounded-[80px] p-6 col-span-3">
          <p className="text-8xl">
            <span className="funnyFont">I vantaggi</span>
            <span className="font-thin"> di essere un partner</span>
            <span className="font-bold text-primary"> esco.zone</span>
          </p>
        </div>
        {/*  ----- dots with icon ------ */}
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={box} alt="risparmio" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={itinerary} alt="easy" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-12 -mt-6">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary">
            <img src={people} alt="target" className="w-12 h-12 mx-auto" />
          </div>
        </div>
        {/* ----- vertical lines ----- */}
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Risparmio</p>
          <p className="text-3xl font-thin md:text-4xl">
            grazie al modello di commissioni più vantaggioso
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Easy</p>
          <p className="text-3xl font-thin md:text-4xl">
            non è necessario avere competenze di marketing
          </p>
        </div>
        <div className="flex flex-col text-center gap-y-4 border-4 border-primary rounded-[60px] p-6">
          <p className="text-6xl funnyFont text-primary">Target</p>
          <p className="text-3xl font-thin md:text-4xl">
            raggiungi il pubblico interessato alla tua proposta di viaggio
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-12 md:mt-36 md:hidden">
        <div className="flex py-12 px-4 items-center justify-center text-center border-4 border-primary rounded-[30px] ">
          <p className="text-3xl">
            <span className="funnyFont">I vantaggi</span>
            <span className="font-thin"> di essere un partner</span>
            <span className="font-bold text-primary"> esco.zone</span>
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={map} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Local</p>
          <p className="text-2xl font-thin">
            il luogo perfetto per fare emergere le piccole realtà locali
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={filter} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Su misura</p>
          <p className="text-2xl font-thin">
            grazie alle diverse categorie si adatta a diverse soluzioni
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={authentic} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Authentic</p>
          <p className="text-2xl font-thin">
            in linea con le nuove tendenze di viaggio esperienziale
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={box} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Risparmio</p>
          <p className="text-2xl font-thin">
            grazie al modello di commissioni più vantaggioso
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={itinerary} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Easy</p>
          <p className="text-2xl font-thin">
            non è necessario avere competenze di marketing
          </p>
        </div>
        <div className="flex items-center justify-center w-full h-12">
          <div className="h-full border-l-4 border-primary" />
        </div>
        <div className="relative z-10 flex items-center justify-center w-full h-10 -mt-4">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
            <img src={people} alt="local" className="w-6 h-6 mx-auto" />
          </div>
        </div>
        <div className="relative -mt-6 flex flex-col text-center gap-y-4 border-4 border-primary rounded-[30px] p-6">
          <p className="text-3xl funnyFont text-primary">Target</p>
          <p className="text-2xl font-thin">
            raggiungi il pubblico interessato alla tua proposta di viaggio
          </p>
        </div>
      </div>
    </>
  );
}

export default function BecomePartnerStructure() {
  const [userLogged, setUserLogged] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const navigate = useNavigate();
  const notify = () =>
    toast.error(
      "Devi verificare la tua email prima di poter caricare una struttura",
      {
        toastId: Math.random().toString(),
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        containerId: "BecomePartnerContainer",
      }
    );

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/loggedEmailVerified`)
        .then((res) => {
          setUserLogged(res.data.logged);
          setEmailVerified(res.data.verified);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  const createStructure = async () => {
    if (!userLogged) {
      navigate("/login");
      return;
    }

    if (!emailVerified) {
      notify();
      return;
    }

    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user");

    const data = {
      user_id,
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/structure`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        const structure_id = res.data._id;
        const dataUser = {
          has_structures: true,
        };
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user_id}`,
            dataUser,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res) => {
            await axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/room`,
                {
                  structure_id: structure_id,
                  name: "Camera 1",
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                navigate(`/proposeStructure/${structure_id}`);
              })
              .catch(() => {
                alert("Errore durante la creazione della stanza");
              });
          })
          .catch(() => {
            alert("Errore durante la modifica dell'utente");
          });
      })
      .catch(() => {
        alert("Errore durante la creazione della struttura");
      });
  };

  useEffect(() => {
    Start();
  }, []);

  const faqList = [
    {
      title: "Come posso diventare partner?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso guadagnare con Esco?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Quali sono i vantaggi di diventare partner?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso contattare il supporto?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso modificare i miei dati personali?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
  ];

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    document.title = "Esco | Diventa partner";
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div id="page-top">
        <header>
          <NavbarCreator fromPropose />
        </header>
        <main>
          <div className="relative">
            <div className="w-full">
              <div className="relative block md:hidden top-[83px]">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/cover_become_partner_structure.jpeg"
                  alt="becomePartnerStructureCover"
                  className="w-full  rounded-bl-[400px] rounded-br-[300px]"
                />
              </div>
              <div className="mx-0 2xl:mx-24">
                <div className="flex flex-col pt-24 mx-4 md:mx-24 md:pt-44 max-w-screen-2xl 2xl:mx-auto">
                  <div className="grid items-center w-full grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-y-6 md:gap-y-4">
                      <div className="relative flex flex-col gap-y-4 md:gap-y-8 max-w-[660px]  ">
                        <div className="flex flex-col text-center gap-y-4 md:text-start">
                          <div className="relative">
                            <h1 className="text-5xl font-semibold md:text-7xl text-escoBlack">
                              Svela il fascino
                            </h1>
                            <div className="absolute hidden -right-6 md:-right-12 top-2 md:block">
                              <img
                                src={magicGreen}
                                alt="magicGreen"
                                className="w-12 mr-8 md:w-32"
                              />
                            </div>
                          </div>
                          <h2 className="text-5xl md:text-7xl text-primary funnyFont">
                            del tuo alloggio unico
                          </h2>
                        </div>
                        <div className="font-semibold text-center md:text-3xl text-escoBlack md:text-start">
                          <p className="mb-8">
                            Ti senti trascurato tra le infinite offerte di
                            alloggi generici?{" "}
                          </p>
                          <p className="pt-2 md:pt-4">
                            <span className="font-bold">esco.zone</span> è qui
                            per dare luce alla tua struttura autentica,
                            aiutandoti a farla scoprire da chi cerca luoghi che
                            raccontano una storia.
                          </p>
                        </div>
                        <button
                          className="relative flex items-center self-center px-12 py-3 font-bold text-white rounded-full gap-x-4 md:text-xl w-fit bg-primary hover:bg-secondary md:self-start"
                          onClick={() => {
                            if (userLogged) {
                              createStructure();
                            } else {
                              navigate("/login");
                            }
                          }}
                        >
                          <span>Carica il tuo alloggio</span>
                          <div></div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="absolute w-4 h-4 md:w-6 md:h-6 right-4"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="absolute right-0 top-20 -right-24 w-[48%]">
                      <img
                        src="https://escopublic.s3.eu-north-1.amazonaws.com/cover_become_partner_structure.jpeg"
                        alt="becomePartnerStructureCover"
                        className="w-full h-full rounded-bl-[400px] rounded-tl-[250px] rounded-br-[300px] hidden md:block "
                      />
                    </div>
                  </div>
                  {/* <img
                    src="https://escopublic.s3.eu-north-1.amazonaws.com/becomePartnerExperienceCover.png"
                    alt="becomePartnerExperienceCover"
                    className="w-full rounded-[30px] mt-36 hidden md:block"
                  /> */}
                  <CentralBecomePartnerMessage />
                  <div className="grid grid-cols-1 md:grid-cols-2 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12 items-center">
                    <div className="flex items-center h-full mt-10">
                      <div className="flex flex-col w-full md:w-3/4 md:flex-row gap-x-12">
                        <div className="flex flex-col w-full">
                          <div className="flex items-center text-white justify-center border-2 border-white rounded-[60px] p-4 font-thin text-3xl md:text-6xl">
                            Scopri se fai
                          </div>
                          <div className="self-center h-10 border-r-2 border-white md:h-12" />
                          <div className="flex items-center justify-center">
                            <div className="absolute w-6 h-6 mt-1 bg-white rounded-full " />
                          </div>
                          <div className=" text-center flex items-center justify-center border-2 border-white text-white rounded-[60px] px-4 py-4 funnyFont text-3xl md:text-7xl">
                            al caso nostro
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block relative w-1/4 h-[35%]">
                        <div className="flex flex-col w-full h-full border-b-2" />
                        <div className="absolute w-6 h-6 bg-white rounded-full -bottom-3 -right-3" />
                      </div>
                    </div>
                    <div className="relative flex items-center justify-center w-full md:hidden">
                      <div className="self-center h-10 border-r-2 border-white md:h-12" />
                      <div className="flex items-center justify-center">
                        <div className="absolute w-6 h-6 mt-10 bg-white rounded-full " />
                      </div>
                    </div>
                    <div className="md:mt-12 border-2 border-white rounded-[30px]">
                      <div className="grid grid-cols-1 text-white">
                        <div className="flex flex-col p-6 gap-y-6">
                          <p className="text-xl font-bold">
                            Strutture ricettive ammesse
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4">
                            <ul className="px-4 list-disc">
                              <li className="text-lg">Agriturismi</li>
                              <li className="text-lg">B&B</li>
                            </ul>
                            <ul className="px-4 list-disc">
                              <li className="text-lg">Rifugi</li>
                              <li className="text-lg">Ostelli</li>
                            </ul>
                            <ul className="px-4 list-disc">
                              <li className="text-lg">Camping</li>
                              <li className="text-lg">Glamping</li>
                            </ul>
                          </div>
                        </div>
                        <div className="flex flex-col p-6 border-t-2 border-white gap-y-6">
                          <p className="text-xl font-bold">
                            Aspetti che ci stanno a cuore
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-4">
                            <ul className="col-span-2 px-4 list-disc">
                              <li className="text-lg">
                                Valorizzazione delle tradizioni locali
                              </li>
                              <li className="text-lg">
                                Posizione immersa nella natura o contesti rurali
                              </li>
                              <li className="text-lg">
                                Stile architettonico in armonia con il
                                territorio
                              </li>
                              <li className="text-lg">
                                Proprietà storiche o tradizionali
                              </li>
                              <li className="text-lg">
                                Utilizzo di prodotti locali
                              </li>
                            </ul>
                            <ul className="px-4 list-disc">
                              <li className="text-lg">
                                Gestione familiare o piccole realtà indipendenti
                              </li>
                              <li className="text-lg">
                                Sostenibilità ambientale
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-4 border-primary rounded-[30px] mt-12 md:mt-36 p-4 md:p-12">
                    <p className="text-4xl font-thin text-center md:text-7xl text-escoBlack">
                      L'attività di{" "}
                      <span className="funnyFont">marketing perfetta </span>
                      per le tue esigenze
                    </p>
                  </div>
                  <TableComparison />
                  <div className="flex flex-col md:flex-row gap-x-12 bg-primary rounded-[30px] mt-12 md:mt-36 p-8 md:p-12">
                    <div className="w-full pb-8 md:w-3/4 md:pb-0">
                      <p className="text-3xl font-thin text-center text-white md:text-6xl">
                        Bastano solo{" "}
                        <span className="relative">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="px-2 md:px-4 py-0.5 md:py-2 text-white border border-white rounded-[40px] funnyFont">
                          15
                        </span>
                        <span className="relative mr-4">
                          <span className="absolute w-3 h-3 mt-4 -ml-1.5 bg-white rounded-full md:-ml-2 md:w-4 md:h-4 md:mt-6"></span>
                        </span>
                        <span className="text-white funnyFont">minuti </span>
                        per caricare la tua struttura
                      </p>
                    </div>
                    <div className="flex flex-col justify-around w-full gap-y-4 md:w-1/4">
                      <button
                        className="w-full px-8 py-2 text-lg text-white border-2 border-white rounded-full hover:bg-secondary"
                        onClick={() => {
                          // window.location.href =
                          //   "https://escopublic.s3.eu-north-1.amazonaws.com/Esco_Guida_Creator.pdf";
                          console.log("download pdf");
                        }}
                      >
                        Scarica il tutorial
                      </button>
                      <button
                        className="w-full px-8 py-2 text-lg bg-white border-2 border-white rounded-full text-primary hover:bg-gray-100"
                        onClick={() => {
                          if (userLogged) {
                            createStructure();
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        Carica la tua struttura
                      </button>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-center w-full">
                    <div className="absolute z-10 w-10 h-10 -mt-1.5 rounded-full bg-secondary"></div>
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <div className="border-2 h-36 md:h-64 border-left border-secondary"></div>
                  </div>
                  <div className="mb-16 md:mb-32">
                    <div className="flex flex-col md:flex-row w-full bg-gray-100 min-h-96 rounded-[60px]">
                      <div className="px-4 pt-8 text-center md:py-12 md:w-1/2 md:px-24 md:text-left">
                        <h1 className="pb-4 text-3xl font-bold leading-tight uppercase md:text-7xl text-escoBlack">
                          Ancora dubbi?
                        </h1>
                        <button
                          className="hidden px-24 py-4 mt-8 text-2xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:block"
                          onClick={() => {
                            navigate("/contactUs");
                          }}
                        >
                          Contattaci
                        </button>
                      </div>
                      <div className="w-full p-4 md:p-8 md:w-1/2">
                        {faqList.map((item, index) => (
                          <SingleFaq key={index} item={item} index={index} />
                        ))}
                      </div>
                      <button
                        className="px-24 py-4 mx-4 mt-0 mb-8 text-xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:hidden"
                        onClick={() => {
                          navigate("/contactUs");
                        }}
                      >
                        <p className="text-xs">Per tutto il resto</p>
                        Contattaci
                      </button>
                    </div>
                    <div className="flex items-center justify-center ">
                      <button
                        className="px-16 py-4 mt-8 text-2xl bg-white border-2 rounded-full md:text-3xl md:px-32 text-primary border-primary hover:bg-primary hover:text-white"
                        onClick={() => {
                          if (userLogged) {
                            createStructure();
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        Diventa partner
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            containerId={`BecomePartnerContainer`}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
            transition={Bounce}
            stacked
          />
        </main>
        <Footer />
      </div>
    </>
  );
}
