import React from "react";

export default function Cover({ isVideo, url, description }) {
  return (
    <div className="relative w-screen h-[80vh] md:h-screen md:min-h-[800px]">
      <div className="absolute top-0 w-full h-[60vh] md:h-[120vh] min-h-[60vh] md:min-h-[1000px]">
        {isVideo ? (
          <video
            controls={false}
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
            disablePictureInPicture={true}
            className="object-cover object-center w-full h-full pointer-events-none"
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={url}
            className="object-cover object-center w-full h-full"
            alt="cover"
          />
        )}
      </div>
      <div className="mx-auto max-w-screen-2xl">
        <div className="absolute z-10 w-1/3 p-8 text-5xl font-bold text-white uppercase md:text-6xl top-20 md:top-24 xl:text-8xl 2xl:top-28">
          {description}
        </div>
      </div>
    </div>
  );
}
