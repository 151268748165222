import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";

export default function PopUp({
  icon,
  items,
  iconStyle,
  setIsOpen,
  goUnderneath = false,
  orientation = "vertical",
  direction = "left",
  length = 0,
  withoutEvent = false,
  isOpen = false,
}) {
  const popUpRef = useRef(null);
  const [popUpOpen, setPopUpOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setPopUpOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (setIsOpen) {
      setIsOpen(popUpOpen);
    }
  }, [popUpOpen]);

  return (
    <div className="relative" ref={popUpRef}>
      {iconStyle === "bordered" ? (
        <div
          onClick={() => {
            if (!withoutEvent) {
              setPopUpOpen((prev) => !prev);
            }
          }}
          className="flex items-center justify-center w-6 h-6 border rounded-full cursor-pointer border-primary"
        >
          {icon}
        </div>
      ) : (
        <>
          {iconStyle === "noBackground" ? (
            <div
              onClick={() => {
                if (!withoutEvent) {
                  setPopUpOpen((prev) => !prev);
                }
              }}
              className="flex items-center justify-center h-6 rounded-full cursor-pointer"
            >
              {icon}
            </div>
          ) : (
            <div
              onClick={() => {
                if (!withoutEvent) {
                  setPopUpOpen((prev) => !prev);
                }
              }}
              className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-primary"
            >
              {icon}
            </div>
          )}
        </>
      )}
      {orientation === "horizontal" && (
        <div
          className={`${
            popUpOpen || isOpen ? "block" : "hidden"
          } absolute z-50 w-52 bg-white border rounded-[30px] shadow-md -left-[220px] ${
            length > 2
              ? "-top-[49px]"
              : length === 2
              ? "-top-[29px]"
              : "-top-[9px]"
          }`}
        >
          {items}
        </div>
      )}
      {(popUpOpen || isOpen) && !goUnderneath && orientation === "vertical" && (
        <div className="absolute right-0 z-50 flex flex-col w-52 mt-0.5 bg-white border rounded-[30px] shadow-md">
          {items}
        </div>
      )}
      {(popUpOpen || isOpen) && goUnderneath && orientation === "vertical" && (
        <div className="absolute -left-16 z-50 flex flex-col w-52 mt-4 bg-white border rounded-[30px] shadow-md">
          {items}
        </div>
      )}
    </div>
  );
}
