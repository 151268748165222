import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React, { useMemo } from "react";

export default function Map({ address, isLoaded, loadError }) {
  const defaultMapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    clickableIcons: false,
  };

  const center = useMemo(
    () => ({
      lat: address.latitude,
      lng: address.longitude,
    }),
    [address]
  );

  if (!isLoaded) return <div>Loading...</div>;

  return (
    isLoaded &&
    !loadError && (
      <div className="rounded-[30px] w-full h-full overflow-hidden">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          zoom={10}
          center={center}
          options={defaultMapOptions}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </div>
    )
  );
}
