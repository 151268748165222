import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function Escobox() {
  const { id } = useParams();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);

  const [joinedEscobox, setJoinedEscobox] = useState(
    experience?.joined_escobox || false
  );
  const [termsAccepted, setTermsAccepted] = useState(
    experience?.terms_accepted_at ? true : false
  );

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    let currentError = [{ error: null }];
    let data = {};

    if (!termsAccepted) {
      currentError = [{ error: "Devi accettare i termini e le condizioni" }];
      setErrors(currentError);
      return;
    }

    setErrors(currentError);

    data = {
      joined_escobox: joinedEscobox,
      ...(termsAccepted && { terms_accepted_at: new Date() }),
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento dell'esperienza");
      });
  };

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, []);

  useEffect(() => {
    if (experience) {
      const token = localStorage.getItem("token");
      const updateTerms = async () => {
        if (!termsAccepted) {
          try {
            const res = await axios.put(
              `${process.env.REACT_APP_SERVER_URL}/api/experience/removeTermAccepted/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setExperience(res.data.experience);
            setErrors([{ error: "Devi accettare i termini e le condizioni" }]);
          } catch {
            alert("Errore durante l'aggiornamento dell'esperienza");
          }
        } else if (termsAccepted && !experience.terms_accepted_at) {
          updateExperience();
        }
      };
      updateTerms();
    }
  }, [termsAccepted]);

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, [joinedEscobox]);

  return (
    <BaseComponent title="Aderisci ad Escobox">
      <div className="flex flex-col gap-y-2 min-h-[500px] overflow-y-auto bg-gray-100 rounded-md px-4 py-4 my-4">
        {experience ? (
          <>
            <div className="flex flex-col gap-4 p-6 text-white rounded-md md:flex-row bg-primary">
              <div className="flex flex-col justify-between gap-4 text-sm md:w-2/3">
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center text-lg gap-x-4">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <p className="font-semibold">
                      Cosa si intende per escobox?
                    </p>
                  </div>
                  <div className="flex justify-center w-full overflow-hidden rounded-md md:hidden">
                    <img
                      src="https://escopublic.s3.eu-north-1.amazonaws.com/escoBoxExample.png"
                      alt="itinerary"
                      className="rounded-md h-72"
                    />
                  </div>
                  <p>
                    Escobox è un servizio di esco che ti permette di ricevere un
                    numero potenzialmente più alto di richieste di
                    partecipazione alle tue esperienze. Se aderisci ad Escobox,
                    il tuo annuncio potrà essere incluso in box venduti dalla
                    piattaforma, che includono più esperienze e alloggi
                    selezionati per offrire un'esperienza completa ai
                    partecipanti.
                  </p>
                </div>
                <label
                  htmlFor="hasEscobox"
                  className="flex items-center text-base font-semibold gap-x-3"
                >
                  <input
                    type="checkbox"
                    id="hasEscobox"
                    name="hasEscobox"
                    className="hidden"
                    defaultChecked={false}
                  />
                  <div
                    className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                    onClick={() => {
                      setJoinedEscobox(!joinedEscobox);
                    }}
                  >
                    {joinedEscobox && (
                      <FontAwesomeIcon className="h-3" icon={faCheck} />
                    )}
                  </div>
                  Voglio aderire ad Escobox
                </label>
              </div>
              <div className="justify-end hidden w-1/3 overflow-hidden rounded-md md:flex">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/escoBoxExample.png"
                  alt="itinerary"
                  className="rounded-md h-72"
                />
              </div>
            </div>
            <label
              htmlFor="termsAcceptedAt"
              className="flex items-center mt-4 text-base font-semibold gap-x-4"
            >
              <input
                type="checkbox"
                id="termsAcceptedAt"
                name="termsAcceptedAt"
                className="hidden"
                defaultChecked={false}
              />
              <div
                className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                onClick={() => {
                  setTermsAccepted(!termsAccepted);
                }}
              >
                {termsAccepted && (
                  <FontAwesomeIcon className="h-3" icon={faCheck} />
                )}
              </div>
              <p>
                Accetto i{" "}
                <span
                  className="cursor-pointer text-primary hover:border-b hover:border-b-primary"
                  onClick={() => {
                    window.open(
                      `https://escopublic.s3.eu-north-1.amazonaws.com/T%26C_PARTNER.pdf`,
                      "_blank"
                    );
                  }}
                >
                  termini e condizioni
                </span>{" "}
                della piattaforma
              </p>
            </label>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
