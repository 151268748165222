import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faMinus,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";
import { ElementsCartContext, FeeContext } from "../App";

function Riepilogo({
  element,
  selectedExperience,
  selectedTime,
  value,
  adults,
  children,
  isPresent,
  experienceFee,
}) {
  const { fees } = useContext(FeeContext);

  if (isPresent) {
    return (
      <div className="flex flex-col flex-1 w-full gap-y-4">
        {adults > 0 && (
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex items-center gap-x-4">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-green-500"
              />
              <div className="font-thin">Adulti</div>
            </div>
            <div className="flex items-center gap-x-2">
              <p>{adults}</p>{" "}
              <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
              <p>
                {Math.ceil(
                  element.price.adult / 100 +
                    (experienceFee * element.price.adult) / 10000
                ).toFixed(0) + ",00"}{" "}
                €
              </p>
            </div>
          </div>
        )}
        {children > 0 && (
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex items-center gap-x-4">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-green-500"
              />
              <div className="font-thin">Bambini</div>
            </div>
            <div className="flex items-center gap-x-2">
              <p>{children}</p>{" "}
              <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
              <p>
                {Math.ceil(
                  element.price.child / 100 +
                    (experienceFee * element.price.child) / 10000
                ).toFixed(0) + ",00"}{" "}
                €
              </p>
            </div>
          </div>
        )}
        <div className="w-full mt-2 border-b border-primary" />
        <div className="flex flex-row justify-between mt-2">
          <div className="font-bold">Totale</div>
          <div className="font-bold">
            {element.price.child
              ? Math.ceil(
                  (element.price.adult / 100 +
                    (experienceFee * element.price.adult) / 10000) *
                    adults +
                    (element.price.child / 100 +
                      (experienceFee * element.price.child) / 10000) *
                      children
                ).toFixed(0) + ",00"
              : element.price.adult
              ? Math.ceil(
                  (element.price.adult / 100 +
                    (experienceFee * element.price.adult) / 10000) *
                    (adults + children)
                ).toFixed(0) + ",00"
              : Math.ceil(
                  (element / 100 + (experienceFee * element.price) / 10000) *
                    (adults + children)
                ).toFixed(0) + ",00"}{" "}
            €
          </div>
        </div>
      </div>
    );
  }

  if (!selectedTime) {
    return <p>Seleziona un orario per poter procedere con la prenotazione</p>;
  }

  return (
    <div className="flex flex-col flex-1 w-full gap-y-4">
      {adults > 0 && (
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
            <div className="font-thin">Adulti</div>
          </div>
          <div className="flex items-center gap-x-2">
            <p>{adults}</p>{" "}
            <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
            <p>
              {Math.ceil(
                element.price.adult / 100 +
                  (experienceFee * element.price.adult) / 10000
              ).toFixed(0) + ",00"}{" "}
              €
            </p>
          </div>
        </div>
      )}
      {children > 0 && (
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
            <div className="font-thin">Bambini</div>
          </div>
          <div className="flex items-center gap-x-2">
            <p>{children}</p>{" "}
            <FontAwesomeIcon icon={faXmark} className="text-xs" />{" "}
            <p>
              {Math.ceil(
                element.price.child / 100 +
                  (experienceFee * element.price.child) / 10000
              ).toFixed(0) + ",00"}{" "}
              €
            </p>
          </div>
        </div>
      )}
      {selectedExperience && (
        <div className="flex items-center gap-x-4">
          <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
          <div className="font-thin">{dayjs(value).format("DD MMMM YYYY")}</div>
        </div>
      )}
      {selectedTime && (
        <div className="flex items-center gap-x-4">
          <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
          <div className="font-thin">
            Orario: {selectedTime.start_time} -{" "}
            {+selectedTime.start_time.split(":")[0] + element.duration}
            :00
          </div>
        </div>
      )}
      <div className="w-full mt-2 border-b border-primary" />
      <div className="flex flex-row justify-between mt-2">
        <div className="font-bold">Totale</div>
        <div className="font-bold">
          {element.price.child
            ? Math.ceil(
                (element.price.adult / 100 +
                  (experienceFee * element.price.adult) / 10000) *
                  adults +
                  (element.price.child / 100 +
                    (experienceFee * element.price.child) / 10000) *
                    children
              ).toFixed(0) + ",00"
            : element.price.adult
            ? Math.ceil(
                (element.price.adult / 100 +
                  (experienceFee * element.price.adult) / 10000) *
                  (adults + children)
              ).toFixed(0) + ",00"
            : Math.ceil(
                (element / 100 + (experienceFee * element.price) / 10000) *
                  (adults + children)
              ).toFixed(0) + ",00"}{" "}
          €
        </div>
      </div>
    </div>
  );
}

export default function AddToCartExperience({
  element,
  setOpenCartMobile = null,
}) {
  const [value, onChange] = useState(new Date());
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [isPresent, setIsPresent] = useState(false);
  const [isPrivateGroup, setIsPrivateGroup] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [message, setMessage] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null);

  const navigate = useNavigate();
  const [cart, setCart] = useState(null);
  const { elements, setElements } = useContext(ElementsCartContext);
  const { fees } = useContext(FeeContext);
  const experienceFee =
    element.fee_id === "associations"
      ? fees.associations.fee
      : element.fee_id
      ? fees.custom.find((f) => f._id === element.fee_id).fee
      : fees.experiences.fee;

  const experiences = [element];

  const steps = [
    {
      name: "Partecipanti",
    },
    ...(isPresent
      ? [
          {
            name: "Messaggio",
          },
        ]
      : element.is_recurrent
      ? [
          {
            name: "Data",
          },
          {
            name: "Orario",
          },
        ]
      : [
          {
            name: "Orario",
          },
        ]),
    {
      name: "Riepilogo",
    },
  ];

  const checkAvailabilityAndExistence = (date) => {
    const dateFormatted = date.toISOString().slice(0, 10);
    const experience = experiences.map((experience) => {
      if (experience.is_recurrent) {
        if (experience.data_info.recurrency_type === "week") {
          const dayOfWeek = date.getDay();
          const experienceDayOfWeek = experience.data_info.week_days;
          const experienceDayOfWeekByNumber = experienceDayOfWeek.map((day) => {
            switch (day) {
              case "lunedi":
                return 1;
              case "martedi":
                return 2;
              case "mercoledi":
                return 3;
              case "giovedi":
                return 4;
              case "venerdi":
                return 5;
              case "sabato":
                return 6;
              default:
                return 0;
            }
          });
          if (experienceDayOfWeekByNumber.includes(dayOfWeek)) {
            return experienceDayOfWeekByNumber.includes(dayOfWeek);
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        const currentDate = new Date(experience.data_info.start_date);
        currentDate.setDate(currentDate.getDate() - 1);

        const formattedDate = currentDate.toLocaleDateString("it", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return (
          new Date(dateFormatted).toLocaleString("it", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) === formattedDate
        );
      }
    });

    const today = new Date();

    today.setDate(today.getDate() - 1);

    const currentDay = today.toLocaleDateString("it", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    if (
      experience.includes(true) &&
      new Date(dateFormatted).toLocaleString("it", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) >= currentDay
    ) {
      console.log("Esperienza disponibile");
      setSelectedExperience(experience);
      return experience;
    } else {
      console.log("Esperienza non disponibile");
      setSelectedExperience(null);
      return null;
    }
  };

  const createCart = (elementToAdd) => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user");
    if (!token) {
      navigate("/login");
      return;
    }
    const data = {
      user_id: user_id,
      elements: [elementToAdd],
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/cart`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        navigate("/cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getCart() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/cart/${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        console.log(res.data.cart, "cart");
        if (!res.data.message) {
          setCart(res.data.cart);
          setElements(res.data.elements);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const updateCart = async (id, data) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/cart/edit/${id}`, data)
      .then((res) => {
        console.log(res);
        navigate("/cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBook = () => {
    const elementToAdd = {
      experience_id: element._id,
      guests_number: {
        adults: adults,
        children: children,
        is_group: isPrivateGroup,
      },
      ...(!isPresent && {
        start_date: value,
        start_time: selectedTime.start_time,
      }),
      message: message,
      total_price: element.price.children
        ? (element.price.adult / 100 +
            (experienceFee * element.price.adult) / 10000) *
            adults +
          (element.price.child / 100 +
            (experienceFee * element.price.child) / 10000) *
            children
        : element.price.adult
        ? (element.price.adult / 100 +
            (experienceFee * element.price.adult) / 10000) *
          (adults + children)
        : (element.price / 100 + (experienceFee * element.price) / 10000) *
          (adults + children),
    };
    if (cart) {
      const data = {
        elements: [...cart.elements, elementToAdd],
      };
      updateCart(cart._id, data);
    } else {
      createCart(elementToAdd);
    }
  };

  useEffect(() => {
    setCurrentStep(0);
    onChange(new Date());
    setAdults(1);
    setChildren(0);
    setIsPrivateGroup(false);
    setSelectedTime(null);
    setMessage("");
    checkAvailabilityAndExistence(new Date());
    getCart();
  }, [isPresent]);

  useEffect(() => {
    setCurrentStep(0);
    onChange(new Date());
    setAdults(1);
    setChildren(0);
    setIsPresent(false);
    setIsPrivateGroup(false);
    setSelectedTime(null);
    setMessage("");
    checkAvailabilityAndExistence(new Date());
    getCart();
  }, [element]);

  return (
    <div className="relative md:sticky flex flex-col bg-white border rounded-[30px] shadow-lg md:top-5 gap-y-2 min-h-[500px] h-full md:h-fit">
      {element.user_id.stripe_status !== "active" && (
        <div className="absolute z-50 top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.8)] rounded-[20px]">
          <div className="flex flex-col items-center justify-center h-full p-6 gap-y-4">
            <div className="p-4 rounded-[20px] bg-gray-100 flex flex-col gap-y-4 justify-center ">
              <p className="text-lg font-semibold">
                L'esperienza non è al momento disponibile :(
              </p>
              {localStorage.getItem("user") === element.user_id._id && (
                <button
                  className="px-4 py-2 text-white rounded-md bg-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/creatorZone/${localStorage.getItem("user")}/accounting`
                    );
                  }}
                >
                  completa il tuo profilo
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="relative flex flex-row justify-between h-16 overflow-hidden bg-gray-200 rounded-full">
        {steps.map((step, index) => (
          <div className="relative flex">
            <div
              className={` flex h-[3.35rem] md:h-[3.25rem] items-center gap-x-4  relative z-10 pl-12 ${
                currentStep === index &&
                index !== 0 &&
                index !== steps.length - 1
                  ? "bg-secondary xl:-ml-20 rounded-r-[30px]"
                  : currentStep === index && index === steps.length - 1
                  ? "bg-secondary absolute -ml-8 xl:-ml-28 rounded-r-[30px]"
                  : currentStep === index && index === 0
                  ? "bg-secondary  rounded-[30px]"
                  : currentStep > index && index !== 0
                  ? "bg-secondary absolute w-24 md:w-36 -ml-20"
                  : currentStep > index && index === 0
                  ? "absolute bg-secondary left-2 w-24 md:w-36 rounded-l-[30px]"
                  : "text-gray-400"
              }`}
            >
              {currentStep === index && (
                <div className="pr-6 font-bold text-white">{step.name}</div>
              )}
            </div>
            <div
              className={`absolute left-0 w-[3rem] md:w-[3.25rem] h-[3.35rem] md:h-[3.25rem] flex items-center justify-center  text-white font-bold ${
                currentStep === index &&
                index !== 0 &&
                index !== steps.length - 1
                  ? "bg-secondary -ml-8 md:-ml-20 w-36 md:w-32 z-20 xl:-ml-24"
                  : currentStep === index && index === steps.length - 1
                  ? "bg-secondary -ml-12 md:-ml-28 w-32 z-20 xl:-ml-32"
                  : currentStep === index && index === 0
                  ? "bg-secondary rounded-full z-20 xl:-ml-1"
                  : currentStep > index && index !== 0
                  ? "bg-primary cursor-pointer rounded-full -ml-10 xl:-ml-24 z-30"
                  : currentStep > index && index === 0
                  ? "bg-primary cursor-pointer rounded-full z-30"
                  : "bg-gray-400 cursor-pointer rounded-full z-20 -ml-1 md:-ml-0"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              {index + 1}
            </div>
          </div>
        ))}
      </div>
      <div className="relative h-full m-6">
        {currentStep === 0 && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col py-4 gap-y-2">
              <div className="flex items-center justify-between">
                <div className="text-lg font-bold">E` un regalo?</div>
                <button
                  className={`rounded-md px-2 border border-primary ${
                    isPresent ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setIsPresent(!isPresent)}
                >
                  Seleziona
                </button>
              </div>
              <p className="w-1/2 font-thin">
                Chi riceve il regalo potrà scegliere data ed orario
              </p>
            </div>
            <div className="text-lg font-bold">Tipologia ospiti</div>
            <div className="flex flex-row justify-between">
              <div className="font-thin">
                Adulti:{" "}
                {Math.ceil(
                  element.price.adult / 100 +
                    (experienceFee * element.price.adult) / 10000
                ).toFixed(0) + ",00"}{" "}
                €
              </div>
              <div className="flex items-center gap-x-2 ">
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => {
                    if (adults > 0) setAdults(adults - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} className="text-xs" />
                </button>
                <div className="w-12 font-semibold text-center">{adults}</div>
                <button
                  className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                  onClick={() => setAdults(adults + 1)}
                >
                  <FontAwesomeIcon icon={faPlus} className="text-xs" />
                </button>
              </div>
            </div>
            {element.price.child && element.price.child !== -1 && (
              <div className="flex flex-row justify-between">
                <div className="font-thin">
                  Bambini:{" "}
                  {(
                    element.price.child / 100 +
                    (experienceFee * element.price.child) / 10000
                  ).toFixed(0) + ",00"}{" "}
                  €
                </div>
                <div className="flex items-center gap-x-2 ">
                  <button
                    className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                    onClick={() => {
                      if (children > 0) setChildren(children - 1);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} className="text-xs" />
                  </button>
                  <div className="w-12 font-semibold text-center">
                    {children}
                  </div>
                  <button
                    className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
                    onClick={() => setChildren(children + 1)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="text-xs" />
                  </button>
                </div>
              </div>
            )}
            {element.price.group_price && element.price.group_price !== -1 && (
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col font-thin">
                  <div>Gruppo privato</div>
                  <div>
                    max {element.max_group_size} persone :{" "}
                    {Math.ceil(
                      element.price.group_price / 100 +
                        (experienceFee * element.price.group_price) / 10000
                    ).toFixed(0) + ",00"}{" "}
                    €
                  </div>
                </div>
                <button
                  className={`rounded-md px-2 border border-primary ${
                    isPrivateGroup ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => setIsPrivateGroup(!isPrivateGroup)}
                >
                  Seleziona
                </button>
              </div>
            )}
          </div>
        )}
        {currentStep === 1 && !isPresent && element.is_recurrent && (
          <div className="flex flex-col mb-16 gap-y-4">
            <div className="font-bold">Seleziona la data</div>
            <Calendar
              onChange={(date) => {
                onChange(date);
                setSelectedExperience(null);
                checkAvailabilityAndExistence(date);
              }}
              value={value}
              minDate={new Date()}
              prev2Label={null}
              next2Label={null}
              tileContent={({ date, view }) => {
                if (view === "month") {
                  const dateFormatted = date.toISOString().slice(0, 10);
                  const experience = experiences.map((experience) => {
                    if (experience.is_recurrent) {
                      if (experience.data_info.recurrency_type === "week") {
                        const dayOfWeek = date.getDay();
                        const experienceDayOfWeek =
                          experience.data_info.week_days;
                        const experienceDayOfWeekByNumber =
                          experienceDayOfWeek.map((day) => {
                            switch (day) {
                              case "lunedi":
                                return 1;
                              case "martedi":
                                return 2;
                              case "mercoledi":
                                return 3;
                              case "giovedi":
                                return 4;
                              case "venerdi":
                                return 5;
                              case "sabato":
                                return 6;
                              default:
                                return 0;
                            }
                          });
                        if (experienceDayOfWeekByNumber.includes(dayOfWeek)) {
                          return experienceDayOfWeekByNumber.includes(
                            dayOfWeek
                          );
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    } else {
                      const currentDate = new Date(
                        experience.data_info.start_date
                      );
                      currentDate.setDate(currentDate.getDate() - 1);

                      const formattedDate = currentDate.toLocaleDateString(
                        "it",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      );
                      return (
                        new Date(dateFormatted).toLocaleString("it", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }) === formattedDate
                      );
                    }
                  });

                  const today = new Date();

                  today.setDate(today.getDate() - 1);

                  const currentDay = today.toLocaleDateString("it", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  });

                  if (
                    experience.includes(true) &&
                    new Date(dateFormatted).toLocaleString("it", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }) >= currentDay
                  ) {
                    return (
                      <div className="w-1 h-1 -mt-2 bg-green-500 rounded-full" />
                    );
                  }
                }
              }}
            />
          </div>
        )}
        {currentStep === 1 && !isPresent && !element.is_recurrent && (
          <div className="flex flex-col flex-1 w-full gap-y-4">
            <div className="text-lg font-bold">Esperienze disponibili</div>
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <div className="flex font-thin gap-x-4 ">
                  <div className="font-bold">
                    {dayjs(new Date(element.data_info.start_date)).format(
                      "DD MMM"
                    )}
                  </div>
                  <div>
                    {element.data_info.start_time} -{" "}
                    {+element.data_info.start_time.split(":")[0] +
                      element.duration}
                    :00
                  </div>
                </div>
                <button
                  className={`rounded-md px-2 border border-primary ${
                    selectedTime ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    if (!selectedTime) {
                      setSelectedTime(element.data_info);
                    } else {
                      setSelectedTime(null);
                    }
                  }}
                >
                  Seleziona
                </button>
              </div>
              <p className="font-thin text-red-500">
                {element.max_group_size} posti rimasti
              </p>
            </div>
          </div>
        )}
        {currentStep === 1 && isPresent && (
          <div className="flex flex-col flex-1 w-full gap-y-4">
            <div className="font-bold">Messaggio</div>
            <div className="w-full h-56">
              <textarea
                className="h-full w-full p-6 mb-6 border-2 border-gray-200 resize-none rounded-[40px]"
                defaultValue={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Aggiungi un messaggio"
              ></textarea>
            </div>
          </div>
        )}
        {currentStep === 2 && !isPresent && element.is_recurrent && (
          <div className="flex flex-col flex-1 w-full gap-y-4">
            <div className="text-lg font-bold">Esperienze disponibili</div>
            {selectedExperience && (
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <div className="flex font-thin gap-x-4 ">
                    <div className="font-bold">
                      {dayjs(value).format("DD MMM")}
                    </div>
                    <div>
                      {element.data_info.start_time} -{" "}
                      {+element.data_info.start_time.split(":")[0] +
                        element.duration}
                      :00
                    </div>
                  </div>
                  <button
                    className={`rounded-md px-2 border border-primary ${
                      selectedTime ? "bg-primary text-white" : ""
                    }`}
                    onClick={() => {
                      if (!selectedTime) {
                        setSelectedTime(element.data_info);
                      } else {
                        setSelectedTime(null);
                      }
                    }}
                  >
                    Seleziona
                  </button>
                </div>
                <p className="font-thin text-red-500">
                  {element.max_group_size} posti rimasti
                </p>
              </div>
            )}
          </div>
        )}
        {currentStep === 2 && !isPresent && !element.is_recurrent && (
          <Riepilogo
            element={element}
            selectedExperience={selectedExperience}
            selectedTime={selectedTime}
            value={value}
            adults={adults}
            children={children}
            isPresent={isPresent}
            experienceFee={experienceFee}
          />
        )}
        {currentStep === 2 && isPresent && (
          <Riepilogo
            element={element}
            selectedExperience={selectedExperience}
            selectedTime={selectedTime}
            value={value}
            adults={adults}
            children={children}
            isPresent={isPresent}
            experienceFee={experienceFee}
          />
        )}
        {currentStep === 3 && (
          <Riepilogo
            element={element}
            selectedExperience={selectedExperience}
            selectedTime={selectedTime}
            value={value}
            adults={adults}
            children={children}
            isPresent={isPresent}
            experienceFee={experienceFee}
          />
        )}
        <div className="absolute bottom-0 flex w-full gap-x-2">
          <button
            className="flex items-center justify-center px-5 py-3 border rounded-full w-fit text-primary border-primary gap-x-2 md:hidden"
            onClick={() => {
              if (currentStep === 0) {
                setOpenCartMobile(false);
              } else {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            <FontAwesomeIcon
              icon={currentStep === 0 ? faXmark : faChevronLeft}
            />
          </button>
          <button
            className="flex items-center justify-center w-full py-3 border rounded-full text-primary border-primary"
            onClick={() => {
              if (currentStep === steps.length - 1) {
                handleBook();
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
            style={
              currentStep === steps.length - 1 && !selectedTime && !isPresent
                ? {
                    border: "1px solid rgb(229 231 235)",
                    color: "rgb(229 231 235)",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <p className="font-semibold">
              {currentStep === steps.length - 1 ? "Prenota" : "Continua"}
            </p>
            {currentStep !== steps.length - 1 && (
              <FontAwesomeIcon icon={faChevronRight} className="mt-1 ml-4" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
