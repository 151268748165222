import { useLoadScript } from "@react-google-maps/api";
import Tooltip from "@uidu/tooltip";
import React, { useEffect } from "react";
import cancellazione from "../assets/icons/CANCELLAZIONE.svg";
import durata from "../assets/icons/DURATA.svg";
import lingue from "../assets/icons/LINGUE.svg";
import mappa from "../assets/icons/MAPPA.svg";
import recensioni from "../assets/icons/REVIEW.svg";
import stella from "../assets/icons/STELLA.svg";
import Map from "../components/Map";
import SingleReview from "../components/SingleReview";
import ExperienceInfoCards from "./experienceComponents/ExperienceInfoCards";
import ItineraryRender from "./experienceComponents/ItineraryRender";

export default function ExperienceBody({ experience }) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const durationTime = (duration) => {
    const hours = +`${duration}`.split(".")[0];
    let minutes = +`${duration}`.split(".")[1];
    if (`${minutes}`.length === 1) {
      minutes = minutes * 10;
    }
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} ${hours === 1 ? "ora" : "ore"}`;
    }
    if (minutes > 0) {
      if (timeString) timeString += " ";
      timeString += `${minutes} min`;
    }
    return timeString;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 gap-8 my-4 md:grid-cols-2">
        <div className="flex flex-col gap-y-8">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img src={durata} alt="durata" className="w-6 mt-0.5 md:mt-0" />
            <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
              Durata{" "}
              <span className="text-base font-normal">
                {durationTime(experience.duration)}
              </span>
            </div>
          </div>
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img
              src={cancellazione}
              alt="cancellazione"
              className="w-6 mt-0.5 md:mt-0"
            />
            <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
              Cancellazione gratuita{" "}
              <span className="text-base font-normal">
                {`fino a ${experience.cancellation_policy} ore prima`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
            <img src={lingue} alt="lingue" className="w-6 mt-0.5 md:mt-0" />
            <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
              Lingua{" "}
              <div>
                {experience.languages.map((language, index) => (
                  <span className="text-base font-normal" key={language}>
                    {language}
                    {index < experience.languages.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <ExperienceInfoCards experience={experience} />
      <div className="w-full border-b border-primary" />
      <div
        className={`grid grid-cols-1 gap-8 ${
          experience.steps.length > 1 ? "md:grid-cols-2" : ""
        }`}
      >
        <div className="hidden md:block">
          {experience.steps && experience.steps.length > 1 && (
            <ItineraryRender experience={experience} />
          )}
        </div>
        <div className="flex flex-col my-4 md:my-0 md:pb-24 gap-y-6">
          <div className="flex flex-col text-lg font-semibold md:gap-2 md:items-end md:flex-row">
            <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
              <img src={mappa} alt="luogo" className="w-6 mt-0.5 md:mt-0" />
              <div className="text-lg font-semibold">Luogo:</div>
            </div>
            <Tooltip
              className="w-full md:w-3/4"
              content={
                experience.address && experience.address?.formatted_address
                  ? experience.address.formatted_address
                  : "Via parrocchia 84"
              }
              position="bottom"
            >
              <div className="mt-1 ml-12 text-base font-normal md:truncate md:ml-4">
                {experience.address && experience.address?.formatted_address
                  ? experience.address.formatted_address
                  : "Via parrocchia 84"}
              </div>
            </Tooltip>
            {/* <a
              href={`https://www.google.com/maps?q=
                ${experience.address?.latitude},${experience.address?.longitude}`}
              className="text-sm text-primary md:hidden"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vedi sulla mappa
            </a> */}
          </div>
          <div className="min-h-[300px]">
            <Map
              address={{
                latitude: experience?.address?.latitude || 45.7658776,
                longitude: experience?.address?.longitude || 10.3767045,
              }}
              isLoaded={isLoaded}
              loadError={loadError}
            />
          </div>
        </div>
        {experience.steps && experience.steps.length > 1 && (
          <>
            <div className="w-full border-b border-primary md:hidden" />
            <div className="mb-4 md:hidden">
              {experience.steps && experience.steps.length > 1 && (
                <ItineraryRender experience={experience} />
              )}
            </div>
          </>
        )}
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex justify-between my-4">
        <div className="flex items-start md:items-center gap-x-6 md:gap-x-2">
          <img
            src={recensioni}
            alt="recensioni"
            className="w-6 mt-0.5 md:mt-0"
          />
          <div className="text-lg font-semibold">Recensioni:</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex items-center gap-x-1.5">
            <img src={stella} alt="stella" className="w-5" />
            <div>
              {experience.reviews ? averageReviews(experience.reviews) : 0}
              /5{" "}
              {windowWidth < 768 &&
                `(${experience.reviews ? experience.reviews.length : 0})`}
            </div>
          </div>
          <div className="hidden md:flex items-center gap-x-1.5 text-base text-primary">
            <div>
              {experience.reviews ? experience.reviews.length : 0} recensioni
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {experience.reviews ? (
          <>
            {experience.reviews.map((review) => (
              <SingleReview key={review.id} review={review} />
            ))}
          </>
        ) : (
          <div className="flex text-gray-400">
            Nessuna recensione disponibile al momento
          </div>
        )}
      </div>
    </>
  );
}
