import { useLoadScript } from "@react-google-maps/api";
import UiduSpinner from "@uidu/spinner";
import AWS from "aws-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import plus from "../assets/icons/PLUS.svg";
import LoadingSpinnerPage from "./LoadingSpinnerPage";

export default function EditUser({ user, setOpenEditInfo, setUser }) {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState(
    user?.profile_pic ? [user.profile_pic] : []
  );
  const [address, setAddress] = useState(
    user?.address?.formatted_address ?? ""
  );
  const [phone, setPhone] = useState(
    user?.phone_number === "-1" || user?.phone_number === null
      ? ""
      : user?.phone_number
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
  });
  const [errors, setErrors] = useState([
    { id: "name", error: null },
    { id: "surname", error: null },
  ]);

  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [tempImages];
          });
        }
      }
    });
  };

  const updateUser = async () => {
    const token = localStorage.getItem("token");
    const name = document.getElementById("name").value;
    const surname = document.getElementById("surname").value;
    let results = [];
    let latLng = {};
    let addressData = {};

    let currentErrors = [
      { id: "name", error: null },
      { id: "surname", error: null },
    ];

    if (!name || (name.length > 0 && name.length < 2)) {
      console.log("name error");
      currentErrors.find((e) => e.id === "name").error =
        "Specifica il nome, dev'essere lungo almeno di 2 caratteri!";
    }

    if (!surname || (surname.length > 0 && surname.length < 2)) {
      currentErrors.find((e) => e.id === "surname").error =
        "Specifica il cognome, dev'essere lungo almeno di 2 caratteri";
    }

    if (currentErrors.find((e) => e.error !== null)) {
      setErrors(currentErrors);
      return;
    }

    if (address && address.length > 0) {
      results = await geocodeByAddress(address);
      latLng = await getLatLng(results[0]);
      addressData = {
        address: address,
        village:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "locality"
          )?.long_name ?? "",
        municipality:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "administrative_area_level_3"
          )?.long_name ?? "",
        province:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "administrative_area_level_2"
          )?.short_name ?? "",
        country:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "country"
          )?.long_name ?? "",
        postal_code:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "postal_code"
          )?.long_name ?? "",
        street_number:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "street_number"
          )?.long_name ?? "",
        route:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "route"
          )?.long_name ?? "",
        latitude: latLng?.lat ?? null,
        longitude: latLng?.lng ?? null,
        place_id: results[0]?.place_id ?? null,
        formatted_address: results[0]?.formatted_address ?? null,
      };

      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/address/create`,
          addressData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          const data = {
            name,
            surname,
            phone_number: !phone || phone.length < 5 ? -1 : phone,
            address: res.data._id,
            ...(uploadedImages.length > 0 && {
              profile_pic:
                uploadedImages[0][0].length > 1
                  ? uploadedImages[0][0]
                  : uploadedImages[0],
            }),
          };

          await axios
            .put(
              `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user._id}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("ressss", res);
              setUser(res.data);
              setOpenEditInfo(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const data = {
        name,
        surname,
        phone_number: !phone || phone.length < 5 ? -1 : phone,
        ...(uploadedImages.length > 0 && {
          profile_pic:
            uploadedImages[0][0].length > 1
              ? uploadedImages[0][0]
              : uploadedImages[0],
        }),
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUser(res.data);
          setOpenEditInfo(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  useEffect(() => {
    if (JSON.stringify(uploadedImages) !== JSON.stringify(user.profile_pic)) {
      //   updateUser();
    }
  }, [uploadedImages]);

  if (!isLoaded) {
    return <LoadingSpinnerPage />;
  }

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex flex-col w-full md:flex-row gap-x-8 ">
        <div className="self-center w-48 h-48 py-2">
          <ImageUploading
            multiple={false}
            value={images}
            onChange={onChange}
            maxNumber={1}
            acceptType={["jpg", "jpeg", "png"]}
            maxFileSize={10 * 1024 * 1024}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors,
            }) => (
              // write your building UI
              <div className="relative w-full h-full upload__image-wrapper">
                <button
                  className=" border-dashed border-gray-400 rounded-[40px] text-gray-700 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                  style={{
                    backgroundColor: isDragging ? "lightblue" : "white",
                    padding: uploadedImages.length > 0 ? "0" : "4rem",
                    borderWidth: uploadedImages.length > 0 ? "0" : "4px",
                  }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  {isUploading ? (
                    <UiduSpinner size="small" className="text-gray-500" />
                  ) : uploadedImages.length > 0 && !isUploading ? (
                    <img
                      src={uploadedImages[0]}
                      alt="profile"
                      className="rounded-[30px] w-48 h-48 object-cover"
                    />
                  ) : (
                    <>
                      <img
                        src={plus}
                        alt="plus"
                        className="w-16 h-16 p-2 mb-1"
                      />
                      {!errors && <>foto profilo</>}
                      {errors && (
                        <div className="w-32">
                          {errors.maxNumber && (
                            <span className="text-red-500">
                              Hai raggiunto il numero massimo di immagini
                            </span>
                          )}
                          {errors.acceptType && (
                            <span className="text-red-500">
                              Il formato dell'immagine non è valido
                            </span>
                          )}
                          {errors.maxFileSize && (
                            <span className="text-red-500">
                              Immagine troppo grande, la dimensione massima è
                              10MB
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </button>
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="flex flex-col w-full py-4 md:w-3/4 gap-y-2">
          <p className="font-semibold">Nome</p>
          <div className="relative flex flex-col w-full gap-y-4">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="name"
              defaultValue={user?.name}
              placeholder={`nome`}
              //   onChange={() => updateExperience()}
            ></input>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "name")?.error}
              </p>
            }
          </div>
          <div className="relative flex flex-col w-full gap-y-2">
            <p className="font-semibold">Cognome</p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surname"
              defaultValue={user?.surname}
              placeholder={`cognome`}
              //   onChange={() => updateExperience()}
            ></input>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "surname")?.error}
              </p>
            }
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full gap-y-2">
        <p className="font-semibold">Numero di telefono</p>
        <PhoneInput
          country="it"
          regions={"europe"}
          placeholder="Numero di telefono"
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          searchStyle={{
            width: "100%",
            height: "35px",
            border: "1px solid rgb(229 231 235)",
          }}
          inputStyle={{
            width: "100%",
            height: "35px",
            border: "1px solid rgb(229 231 235)",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          }}
          specialLabel=""
        />
      </div>
      <div className="relative flex flex-col w-full gap-y-2">
        <p className="font-semibold">Indirizzo di residenza</p>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          // onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Inserisci il tuo indirizzo",
                  className:
                    "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                })}
              />
              <div className="relative z-30 w-full bg-white rounded-b-lg shadow-lg">
                <div className="absolute top-0 left-0 w-full overflow-y-auto bg-gray-200 rounded-b-lg max-h-60">
                  {loading ? (
                    <div className="w-full px-3 py-1 bg-white">
                      <UiduSpinner size="small" />
                    </div>
                  ) : (
                    <>
                      {suggestions.map((suggestion) => {
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.id}
                            className="w-full px-3 py-1 bg-white cursor-pointer hover:bg-sky-50"
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="flex justify-end w-full mt-16 mb-8 gap-x-8">
        <button
          className="flex items-center justify-center px-4 py-2 text-white bg-gray-400 rounded-md"
          type="button"
          onClick={() => setOpenEditInfo(false)}
        >
          Annulla
        </button>
        <button
          className="flex items-center justify-center px-4 py-2 text-white rounded-md bg-primary"
          type="button"
          onClick={() => updateUser()}
        >
          Salva
        </button>
      </div>
    </div>
  );
}
