import {
  faEllipsisH,
  faExclamationTriangle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FeeContext } from "../../App";
import elimina from "../../assets/icons/ELIMINA.svg";
import BaseComponent from "../BaseComponent";
import ModalSelectFee from "../ModalSelectFee";
import PopUp from "../PopUp";
import StructureIcon from "../StructureIcon";

export default function Structures() {
  const [structures, setStructures] = useState(null);
  const [openSuspend, setOpenSuspend] = useState(null);
  const [openRemoveSuspension, setOpenRemoveSuspension] = useState(null);
  const [openFeeModal, setOpenFeeModal] = useState(false);
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [loading, setLoading] = useState(true);
  const { fees } = useContext(FeeContext);

  const structuresDetail = async () => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/structuresApproved`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.msg === "No structures approved") {
          setStructures([]);
        } else {
          setStructures(res.data);
        }
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const deleteStructure = async () => {
    const token = localStorage.getItem("token");
    const structureDetails = openSuspend
      ? openSuspend._id
      : openRemoveSuspension._id;
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${structureDetails}`,
        {
          is_suspended: openSuspend ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("response", res); // 'response', 'ok
        setStructures((prev) =>
          prev.map((structure) =>
            structure._id === structureDetails
              ? { ...structure, is_suspended: openSuspend ? true : false }
              : structure
          )
        );
        setOpenSuspend(null);
        setOpenRemoveSuspension(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    structuresDetail();
  }, []);

  return (
    <BaseComponent title="Strutture">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[512px] overflow-x-auto overflow-y-auto py-4 px-10 my-4">
        {!loading && structures ? (
          <div className="flow-root h-[512px] ">
            <div className="py-4 -mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                      >
                        Categoria
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Struttura
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Stelle
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Utente
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Fee
                      </th>
                      <th
                        scope="col"
                        className="relative py-3 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Preview</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 ">
                    {structures.map((structure, index) => (
                      <tr
                        key={structure._id}
                        className={structure.is_suspended ? `opacity-50` : ``}
                      >
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                          <div className="flex items-center">
                            <div className="flex items-center justify-center w-12 h-8 rounded-full ">
                              <StructureIcon
                                kind={structure.kind}
                                color={"black"}
                                dimension={6}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {structure.name}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {Array.from({ length: structure.stars }, (_, i) => (
                              <FontAwesomeIcon
                                icon={faStar}
                                className="text-xs text-primary"
                              />
                            ))}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {structure.user_id.name} {structure.user_id.surname}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {structure.fee_id === "associations"
                              ? fees.associations.fee
                              : structure.fee_id
                              ? fees.custom.find(
                                  (f) => f._id === structure.fee_id
                                ).fee
                              : fees.structures.fee}{" "}
                            %
                          </div>
                        </td>
                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                          <div className="flex items-center justify-end gap-x-6">
                            {!structure.is_suspended && (
                              <Link
                                to={`/structures/${structure._id}`}
                                className="text-primary hover:text-primary-dark"
                              >
                                Vedi sul sito
                              </Link>
                            )}
                            <PopUp
                              icon={
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="text-xs text-white"
                                />
                              }
                              orientation="horizontal"
                              length={1}
                              items={
                                <>
                                  <button
                                    onClick={() => {
                                      setOpenFeeModal(true);
                                      setSelectedStructure(structure);
                                    }}
                                    type="button"
                                    className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                                  >
                                    <div>Modifica fee</div>
                                  </button>
                                  {structure.is_suspended ? (
                                    <button
                                      onClick={() =>
                                        setOpenRemoveSuspension(structure)
                                      }
                                      type="button"
                                      className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                                    >
                                      <img
                                        src={elimina}
                                        alt="elimina"
                                        className="w-[22px] pr-2"
                                      />
                                      <div>Rimuovi sospensione</div>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => setOpenSuspend(structure)}
                                      type="button"
                                      className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                                    >
                                      <img
                                        src={elimina}
                                        alt="elimina"
                                        className="w-[22px] pr-2"
                                      />
                                      <div>Sospendi</div>
                                    </button>
                                  )}
                                </>
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {openFeeModal && (
                  <ModalSelectFee
                    setOpenModal={setOpenFeeModal}
                    kind={"structure"}
                    entityId={selectedStructure._id}
                    entities={structures}
                    setEntities={setStructures}
                  />
                )}
              </div>
            </div>
            <ModalTransition>
              {(openSuspend || openRemoveSuspension) && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
                  <Modal
                    onClose={() => {
                      setOpenSuspend(null);
                      setOpenRemoveSuspension(null);
                    }}
                    className="mt-0 md:mt-20"
                    shouldCloseOnOverlayClick={false}
                  >
                    <ModalHeader>
                      <div className="flex items-center gap-x-4">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="w-6 h-6 text-yellow-500"
                        />
                        <h3 className="text-2xl">Attenzione</h3>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenSuspend(null);
                          setOpenRemoveSuspension(null);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </ModalHeader>
                    <ModalBody>
                      {openSuspend ? (
                        <div className="text-gray-400 ">
                          Sei sicuro di voler sospendere la struttura{" "}
                          {openSuspend.name}?
                          <br /> La struttura non sarà più visibile sul sito.
                        </div>
                      ) : (
                        <div className="text-gray-400 ">
                          Sei sicuro di voler rimuovere la sospensione della
                          struttura {openRemoveSuspension.name}?
                          <br /> La struttura sarà nuovamente visibile sul sito.
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
                        <button
                          className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                          onClick={() => {
                            setOpenSuspend(null);
                            setOpenRemoveSuspension(null);
                          }}
                        >
                          Annulla
                        </button>
                        <button
                          className="w-full h-10 text-sm text-white bg-red-500 rounded-lg md:w-28"
                          onClick={() => deleteStructure()}
                        >
                          Conferma
                        </button>
                      </div>
                    </ModalFooter>
                  </Modal>
                </div>
              )}
            </ModalTransition>
          </div>
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-[480px]">
            Nessuna struttura trovata
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
