import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import backToUser from "../assets/icons/BACKTOUSER.svg";
import disponibilita from "../assets/icons/DISPONIBILITA.svg";
import escoBot from "../assets/icons/ESCOBOT.svg";
import esperienza from "../assets/icons/ESPERIENZA.svg";
import lettura from "../assets/icons/LETTURA.svg";
import manage from "../assets/icons/MANAGE.svg";
import messaggi from "../assets/icons/MESSAGGI.svg";
import persone from "../assets/icons/PERSONE.svg";
import prenotazioni from "../assets/icons/PRENOTAZIONI.svg";
import struttura from "../assets/icons/STRUTTURA.svg";
import BaseComponent from "../components/BaseComponent";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import NavbarCreator from "../components/NavbarCreator";
import UserProfileCard from "../components/UserProfileCard";
import Articles from "../components/adminArea/Articles";
import Boxes from "../components/adminArea/Boxes";
import ExperienceToApprove from "../components/adminArea/ExperienceToApprove";
import Experiences from "../components/adminArea/Experiences";
import ManageWebsite from "../components/adminArea/ManageWebsite";
import Settings from "../components/adminArea/Settings";
import StructureToApprove from "../components/adminArea/StructureToApprove";
import Structures from "../components/adminArea/Structures";
import SupportTickets from "../components/adminArea/SupportTickets";
import Users from "../components/adminArea/Users";

function SingleLink({ item, baseUrl }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const isSelected =
    item.label === "Strutture da approvare"
      ? window.location.pathname.split("/").pop() === id
      : `/${window.location.pathname.split("/").pop()}` === item.link;

  return (
    <Link
      to={
        item.link === "/profile" ? `/profile/${id}` : `${baseUrl}${item.link}`
      }
      className="flex items-center w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
      style={{
        borderColor: isSelected ? "#246155" : "",
      }}
    >
      <img src={item.iconDark} alt={item.label} className="w-5 h-5 mr-4" />
      <p className="font-semibold">{item.label}</p>
    </Link>
  );
}

export default function AdminArea() {
  const { id, tab } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState(null);
  const [user, setUser] = useState({});

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
      setLoading(false);
    }
  }

  async function GetUser() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}`)
        .then((res) => {
          setLoading(false);
          if (res.data.is_admin) {
            setUser(res.data);
          } else {
            setUserLogged(false);
            navigate(`/profile/${id}`, { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (userLogged) {
      GetUser();
    }
  }, [userLogged]);

  useEffect(() => {
    Start();
  }, []);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  const schema = [
    {
      label: "Strutture da approvare",
      iconDark: struttura,
      link: "",
      component: <StructureToApprove />,
    },
    {
      label: "Esperienze da approvare",
      iconDark: prenotazioni,
      link: "/approveExperiences",
      component: <ExperienceToApprove />,
    },
    {
      label: "EscoShare da approvare",
      iconDark: disponibilita,
      link: "/escoShare",
    },
    {
      label: "Escobox",
      iconDark: messaggi,
      link: "/escoBox",
      component: <Boxes />,
    },
    {
      label: "Articoli",
      iconDark: lettura,
      link: "/articles",
      component: <Articles />,
    },
    {
      label: "Gestione sito",
      iconDark: manage,
      link: "/homePage",
      component: <ManageWebsite />,
    },
    {
      label: "Richieste di supporto",
      iconDark: escoBot,
      link: "/supportRequests",
      component: <SupportTickets />,
    },
    {
      label: "Esperienze",
      iconDark: esperienza,
      link: "/experiences",
      component: <Experiences />,
    },
    {
      label: "Strutture",
      iconDark: struttura,
      link: "/structures",
      component: <Structures />,
    },
    {
      label: "Utenti",
      iconDark: persone,
      link: "/users",
      component: <Users />,
    },
    {
      label: "Fee Esco",
      iconDark: manage,
      link: "/settings",
      component: <Settings />,
    },
    {
      label: "Back to user",
      iconDark: backToUser,
      link: "/profile",
    },
  ];

  if (!loading && userLogged) {
    document.title = "Esco | Admin Area";

    return (
      <div>
        <header>
          <NavbarCreator />
        </header>
        <main>
          <div className="mx-0 2xl:mx-8">
            <div className="flex mx-8 mt-32 max-w-screen-2xl 2xl:mx-auto">
              <div className="flex flex-col w-1/4 h-screen gap-y-4 ">
                <UserProfileCard user={user} setUser={setUser} />
                <div className="flex flex-col items-center justify-center w-full bg-gray-100 rounded-[20px] gap-y-0.5 ">
                  {schema.map((item, index) => (
                    <SingleLink
                      key={index}
                      item={item}
                      baseUrl={`/adminArea/${id}`}
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col w-3/4 h-screen ">
                {tab ? (
                  <>
                    {schema.find((item) => item.link === `/${tab}`)
                      .component ? (
                      schema.find((item) => item.link === `/${tab}`).component
                    ) : (
                      <BaseComponent
                        title={
                          schema.filter((item) => item.link === `/${tab}`)[0]
                            .label
                        }
                      />
                    )}
                  </>
                ) : (
                  schema[0].component
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
