import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { FavouriteContext, FeeContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import StructureIcon from "./StructureIcon";

export default function SingleStructure({ item }) {
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `SingleStructureContainer`,
    });

  const { favourite, setFavourite } = useContext(FavouriteContext);
  const { fees } = useContext(FeeContext);
  const structureFee =
    item.fee_id === "associations"
      ? fees.associations.fee
      : item.fee_id
      ? fees.custom.find((f) => f._id === item.fee_id).fee
      : fees.structures.fee;

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].structures.find(
          (structure) => structure.structure_id === item._id
        )
      ) {
        const newStructures = favourite[0].structures.filter(
          (structure) => structure.structure_id !== item._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: newStructures,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura rimossa dai preferiti con successo");
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].structures
                  : []),
                { structure_id: item._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura aggiunta ai preferiti con successo");
          });
      }
    }
  };

  return (
    <div
      key={item.id}
      className="relative flex flex-col mb-10 border rounded-[30px] shadow-lg cursor-pointer hover:shadow-xl"
      onClick={() => {
        navigate(`/structures/${item._id}`);
      }}
    >
      <div
        style={{
          backgroundImage: `url(${item.images[0]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt={item.name}
        className="relative w-full h-56 rounded-[30px] shadow-[inset_0px_60px_40px_0px_rgba(0,0,0,0.6)]"
      />
      <div className="absolute flex flex-row justify-between w-full p-2 top-2">
        <div className="flex items-center px-2">
          <StructureIcon kind={item.kind} color="white" dimension={6} />
        </div>
        <button
          className="flex items-center justify-center bg-white rounded-full w-9 h-9"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addToFavourites();
          }}
        >
          {favourite &&
          favourite.length > 0 &&
          favourite[0].structures.find(
            (structure) => structure.structure_id === item._id
          ) ? (
            <img src={heartFull} alt="heartFull" className="w-5" />
          ) : (
            <img src={heart} alt="heart" className="w-5" />
          )}
        </button>
      </div>
      <div className="flex flex-col flex-1 p-2">
        <div className="h-16 pb-2 font-semibold text-center ">{item.name}</div>
        <div className="flex flex-row justify-around text-sm">
          <div className="flex items-center gap-x-2">
            <img src={geotag} alt="geotag" className="w-4" />
            <div className="text-center">
              {item.address
                ? item.address.municipality
                  ? item.address.municipality
                  : item.address.village
                : "Pertica Bassa"}
            </div>
          </div>
          <div className="flex items-center gap-x-1.5">
            <img src={stella} alt="stella" className="w-4" />
            <div>{item.reviews ? averageReviews(item.reviews) : 0}/5</div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center p-2 mt-2 bg-gray-100 rounded-b-lg">
        <div className="font-semibold">
          {Math.ceil(
            item.minRoomPrice / 100 + (structureFee * item.minRoomPrice) / 10000
          ).toFixed(0)}{" "}
          €{" "}
        </div>
        <div className="flex items-center gap-x-1">
          <div className="text-sm text-gray-400">/ notte </div>
        </div>
      </div>
    </div>
  );
}
