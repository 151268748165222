import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function Legal() {
  const { id } = useParams();

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [termsAccepted, setTermsAccepted] = useState(
    structure?.terms_accepted_at ? true : false
  );

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    let currentError = [{ error: null }];

    if (document.getElementById("license").value.length < 5) {
      currentError = [{ error: "Il numero di licenza non è valido" }];
    }

    if (!termsAccepted) {
      currentError = [{ error: "Devi accettare i termini e le condizioni" }];
      setErrors(currentError);
      return;
    }

    setErrors(currentError);

    if (currentError.find((e) => e.error !== null)) return;
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        {
          license_number: document.getElementById("license").value,
          ...(termsAccepted && { terms_accepted_at: new Date() }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    if (structure) {
      const token = localStorage.getItem("token");
      const updateTerms = async () => {
        if (!termsAccepted) {
          try {
            const res = await axios.put(
              `${process.env.REACT_APP_SERVER_URL}/api/structure/removeTermAccepted/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setStructure(res.data.structure);
            setErrors([{ error: "Devi accettare i termini e le condizioni" }]);
          } catch {
            alert("Errore durante l'aggiornamento della struttura");
          }
        } else if (termsAccepted && !structure.terms_accepted_at) {
          updateStructure();
        }
      };
      updateTerms();
    }
  }, [termsAccepted]);

  return (
    <BaseComponent title="Info legali">
      <div className="bg-gray-100 flex flex-col gap-y-6 rounded-md h-[400px] overflow-y-auto py-4 px-4 md:px-10 my-4">
        {structure ? (
          <div className="flex flex-col h-full gap-y-4">
            <div className="flex flex-col gap-y-4">
              <p className="font-semibold">Numero licenza</p>
              <input
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Numero licenza"
                name="license"
                id="license"
                defaultValue={structure?.license_number}
                onChange={() => updateStructure()}
              />
            </div>
            {/* {error && <p className="-mt-2 text-sm text-gray-400">{error}</p>} */}
            <label
              htmlFor="termsAcceptedAt"
              className="flex items-center text-base font-semibold gap-x-4 "
            >
              <input
                type="checkbox"
                id="termsAcceptedAt"
                name="termsAcceptedAt"
                className="hidden"
                defaultChecked={false}
              />
              <div
                className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                onClick={() => {
                  setTermsAccepted(!termsAccepted);
                }}
              >
                {termsAccepted && (
                  <FontAwesomeIcon className="h-3" icon={faCheck} />
                )}
              </div>
              <p>
                Accetto i{" "}
                <span
                  className="cursor-pointer text-primary hover:border-b hover:border-b-primary"
                  onClick={() => {
                    window.open(
                      `https://escopublic.s3.eu-north-1.amazonaws.com/T%26C_PARTNER.pdf`,
                      "_blank"
                    );
                  }}
                >
                  termini e condizioni
                </span>{" "}
                della piattaforma
              </p>
            </label>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
