import {
  faArrowRight,
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export function SingleFaq({ item, index }) {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="w-full "
      style={{
        paddingRight:
          windowWidth >= 768 ? (index % 2 === 0 ? "0" : "32px") : "0",
        paddingLeft:
          windowWidth >= 768 ? (index % 2 === 0 ? "32px" : "0") : "0",
      }}
    >
      <div
        className="flex flex-col items-start justify-between w-full px-8 py-3 mb-4 border cursor-pointer border-primary"
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          borderRadius: open ? "30px" : "60px",
        }}
      >
        <div className="flex items-center justify-between w-full">
          <h1 className="font-bold text-escoBlack">{item.title}</h1>
          <FontAwesomeIcon
            icon={open ? faChevronCircleDown : faChevronCircleRight}
            className="text-2xl text-primary"
          />
        </div>
        {open && (
          <p className="text-normal text-escoBlack">{item.description}</p>
        )}
      </div>
    </div>
  );
}

export default function BecomePartner() {
  const navigate = useNavigate();

  const schema = [
    {
      backgroundUrl:
        "https://escopublic.s3.eu-north-1.amazonaws.com/propose_experience.jpg",
      title: "Condividi le tue passioni",
      description:
        "Fai vivere la tua unicità. Offri esperienze autentiche ai viaggiatori e valorizza ciò che fai con Esco.zone. Unisciti a noi oggi e cresci insieme a una comunità che apprezza l’autenticità.",
      buttonText: "Proponi un'esperienza",
      buttonLink: "/proposeExperience",
    },
    {
      backgroundUrl:
        "https://escopublic.s3.eu-north-1.amazonaws.com/propose_structure.jpg",
      title: "Dai valore al tuo luogo magico",
      description:
        "Accogli viaggiatori in cerca di una vera connessione con il territorio. Con Esco.zone, il tuo alloggio diventa un rifugio unico, capace di regalare momenti indimenticabili. Registrati ora e invita i tuoi ospiti a scoprire le tradizioni e il fascino del tuo angolo di mondo.",
      buttonText: "Proponi una struttura",
      buttonLink: "/proposeStructure",
    },
    // {
    //   backgroundUrl:
    //     "https://escopublic.s3.eu-north-1.amazonaws.com/rewards.png",
    //   title: "Partecipa al programma rewards",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    //   buttonText: "Scopri di più",
    //   buttonLink: "/rewardsProgram",
    // },
  ];

  const faqList = [
    {
      title: "Come posso diventare partner?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso guadagnare con Esco?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Quali sono i vantaggi di diventare partner?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso contattare il supporto?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
    {
      title: "Come posso modificare i miei dati personali?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc. Sed euismod, diam id rhoncus aliquam, nisl nisi faucibus augue, nec ultricies diam nulla vitae nunc.",
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    document.title = "Esco | Diventa partner";
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');
          .funnyFont {
            font-family: 'Caprasimo', sans-serif;
          }
        `}</style>
      </Helmet>
      <div id="page-top">
        <header>
          <Header />
        </header>
        <main>
          <div className="relative">
            <div className="w-full ">
              <div className="flex flex-col">
                {schema.map((item, index) => (
                  <div
                    key={index}
                    className="relative w-full"
                    style={{
                      height: index !== 0 ? "1000px" : "700px",
                      borderRadius:
                        windowWidth >= 768
                          ? index % 2 === 0
                            ? "0 0 300px 0"
                            : "0 0 0 300px"
                          : index % 2 === 0
                          ? "0 0 150px 0"
                          : "0 0 0 150px",
                      transform:
                        index === 0 ? "translateY(0)" : "translateY(-300px)",
                      zIndex: 5 - index,
                      marginBottom: index !== 0 ? "-300px" : "0",
                    }}
                  >
                    <img
                      src={item.backgroundUrl}
                      className="object-cover object-center w-full h-full"
                      style={{
                        borderRadius:
                          windowWidth >= 768
                            ? index % 2 === 0
                              ? "0 0 300px 0"
                              : "0 0 0 300px"
                            : index % 2 === 0
                            ? "0 0 80px 0"
                            : "0 0 0 80px",
                      }}
                      alt="cover"
                    />
                    <div
                      className="absolute top-0 z-10 flex flex-col items-start justify-end w-full h-full bg-black bg-opacity-30 shadow-[0px_20px_50px_0px_rgba(255,255,255,0.25)]"
                      style={{
                        borderRadius:
                          windowWidth >= 768
                            ? index % 2 === 0
                              ? "0 0 300px 0"
                              : "0 0 0 300px"
                            : index % 2 === 0
                            ? "0 0 80px 0"
                            : "0 0 0 80px",
                        background:
                          index % 2 === 0
                            ? "linear-gradient(253deg, rgba(34, 45, 45, 0.00) -7.15%, #222D2D 76.29%)"
                            : "linear-gradient(73deg, rgba(34, 45, 45, 0.00) -7.15%, #222D2D 76.29%)",
                        mixBlendMode: "hard-light",
                      }}
                    />
                    <div className="absolute top-0 z-20 w-full h-full">
                      <div className="flex items-center justify-center h-full mx-0 2xl:mx-8 ">
                        <div
                          className="flex flex-col mx-8 gap-y-4 max-w-screen-2xl 2xl:mx-auto"
                          style={{
                            alignItems:
                              windowWidth >= 768
                                ? index % 2 === 0
                                  ? "flex-start"
                                  : "flex-end"
                                : "center",
                            paddingTop:
                              windowWidth >= 768
                                ? index !== 0
                                  ? "350px"
                                  : "80px"
                                : index !== 0
                                ? "250px"
                                : "20px",
                          }}
                        >
                          <h1
                            className="mb-6 text-4xl text-white md:text-7xl funnyFont"
                            style={{
                              width: windowWidth >= 768 ? "50%" : "100%",
                            }}
                          >
                            {item.title}
                          </h1>
                          <p className="w-full font-semibold text-white md:w-1/2 md:text-xl">
                            {item.description}
                          </p>
                          <button
                            className="relative flex justify-between w-full py-4 pl-8 pr-16 mt-8 text-xl font-bold text-white rounded-full md:w-fit gap-x-12 md:pl-12 md:pr-20 md:text-3xl bg-primary hover:bg-secondary"
                            onClick={() => {
                              navigate(item.buttonLink);
                            }}
                          >
                            <div>{item.buttonText}</div>
                            <div className="absolute right-6">
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="relative flex items-center justify-center w-full">
                  <div className="absolute z-10 w-10 h-10 -mt-1.5 rounded-full bg-secondary"></div>
                </div>
                <div className="flex items-center justify-center w-full">
                  <div className="border-2 h-36 md:h-64 border-left border-secondary"></div>
                </div>
                <div className="mx-0 2xl:mx-8">
                  <div className="mx-4 mb-16 md:mb-32 md:mx-24 max-w-screen-2xl 2xl:mx-auto">
                    <div className="flex flex-col md:flex-row w-full bg-gray-100 min-h-96 rounded-[60px]">
                      <div className="px-4 pt-8 text-center md:py-12 md:w-1/2 md:px-24 md:text-left">
                        <h1 className="pb-4 text-3xl font-bold leading-tight uppercase md:text-7xl text-escoBlack">
                          Ancora dubbi?
                        </h1>
                        <button
                          className="hidden px-24 py-4 mt-8 text-2xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:block"
                          onClick={() => {
                            navigate("/contactUs");
                          }}
                        >
                          Contattaci
                        </button>
                      </div>
                      <div className="w-full p-4 md:p-8 md:w-1/2">
                        {faqList.map((item, index) => (
                          <SingleFaq key={index} item={item} index={index} />
                        ))}
                      </div>
                      <button
                        className="px-24 py-4 mx-4 mt-0 mb-8 text-xl text-white rounded-full md:text-3xl bg-escoBlack hover:bg-black md:hidden"
                        onClick={() => {
                          navigate("/contactUs");
                        }}
                      >
                        <p className="text-xs">Per tutto il resto</p>
                        Contattaci
                      </button>
                    </div>
                    {/* <div className="flex items-center justify-center ">
                  <button
                    className="px-32 py-4 mt-8 text-3xl bg-white border-2 rounded-full text-primary border-primary hover:bg-primary hover:text-white"
                    onClick={() => {
                      createExperience();
                    }}
                  >
                    Diventa partner
                  </button>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
