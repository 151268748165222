import { faker } from "@faker-js/faker";
import { useLoadScript } from "@react-google-maps/api";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FeeContext } from "../App";
import heart from "../assets/heart.svg";
import indietro_white from "../assets/icons/INDIETRO_WHITE.svg";
import CardUser from "../components/CardUser";
import Cover from "../components/Cover";
import ExperienceBody from "../components/ExperienceBody";
import ExperienceSubtitle from "../components/experienceComponents/ExperienceSubtitle";
import ExperienceIcon from "../components/ExperienceIcon";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import ModalSelectFee from "../components/ModalSelectFee";
import ModalSuggestEdit from "../components/ModalSuggestEdit";
import NavbarCreator from "../components/NavbarCreator";

export default function ExperiencePreview() {
  const { id } = useParams();

  const [experience, setExperience] = useState(null);
  const [expandText, setExpandText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFeeModal, setOpenFeeModal] = useState(false);
  const { fees } = useContext(FeeContext);
  const location = useLocation();
  const [userLogged, setUserLogged] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });

  const detailImages = [
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "nature",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "hotel",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "hotel",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "experience",
      width: 600,
      height: 400,
    }),
  ];

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  const userDetail = async () => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.is_admin) {
          setLoading(false);
        } else {
          navigate(`/profile/${user}`, { replace: true });
        }
      })
      .catch(() => {
        alert("Errore durante il caricamento della struttura");
      });
  };

  const experienceDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/experience/${id}`)
      .then((res) => {
        console.log("experience", res.data);
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento dell'esperienza");
      });

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    let data = null;
    data = {
      request_of_approval_made_at: new Date(),
    };
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        if (location.pathname === `/adminExperience/preview/${id}`) {
          window.location.href = `/adminArea/${user}/approveExperiences`;
        } else {
          window.location.href = `/creatorZone/${user}`;
        }
      })
      .catch(() => {
        alert("Errore durante il caricamento dell'esperienza");
      });
  };

  const requestReview = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/requestReview/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setIsLoading(false);
        window.location.href = `/creatorZone/${user}`;
      })
      .catch(() => {
        setIsLoading(false);
        alert("Errore durante la richiesta di revisione");
      });
  };

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userLogged) {
      experienceDetail().then((res) => {
        if (location.pathname === `/adminExperience/preview/${id}`) {
          userDetail();
        } else {
          setLoading(false);
        }
        setExperience(res.experience);
      });
    }
  }, [userLogged]);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  document.title = "Esco | Anteprima esperienza";

  const durationTime = (duration) => {
    const hours = +`${duration}`.split(".")[0];
    let minutes = +`${duration}`.split(".")[1];
    if (`${minutes}`.length === 1) {
      minutes = minutes * 10;
    }
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} ${hours === 1 ? "ora" : "ore"}`;
    }
    if (minutes > 0) {
      if (timeString) timeString += " ";
      timeString += `${minutes} min`;
    }
    return timeString;
  };

  return (
    <div id="page-top">
      <header>
        <NavbarCreator />
      </header>
      <main>
        {experience ? (
          <>
            <div>
              <Cover
                url={
                  experience.images.length > 0
                    ? experience.images[0]
                    : "https://www.thrillophilia.com/blog/wp-content/uploads/2017/05/shutterstock_327692783.jpg"
                }
                isVideo={false}
              />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-md-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-center w-full px-4 mt-8 mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      <div className="mt-1 md:mt-0">
                        <div
                          className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                          style={
                            experience.category === "wine"
                              ? { backgroundColor: "#612430" }
                              : experience.category === "art"
                              ? { backgroundColor: "#C8531C" }
                              : experience.category === "outdoor"
                              ? { backgroundColor: "#4C8AFB" }
                              : experience.category === "authentic"
                              ? { backgroundColor: "#CA9526" }
                              : null
                          }
                        >
                          <ExperienceIcon
                            kind={experience.category}
                            dimension={windowWidth >= 768 ? 6 : 5}
                          />
                        </div>
                      </div>
                      <h1 className="text-3xl text-center md:text-4xl text-secondary">
                        {experience.name}
                      </h1>
                      <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
                        <img src={heart} alt="heart" className="h-10" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-8 md:grid lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-4 md:gap-y-6">
                        <ExperienceSubtitle experience={experience} />
                        <div className="flex flex-row w-full">
                          {experience.tags.map((tag) => (
                            <div
                              key={tag}
                              className="flex items-center justify-center px-2 ml-3 border rounded-md shadow-md text-primary border-primary"
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {experience.description}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {" "}
                            {!expandText
                              ? "Continua a leggere..."
                              : "Mostra meno"}
                          </button>
                        </div>
                        <ImageGroup
                          images={
                            experience.images.length > 0
                              ? experience.images
                              : detailImages
                          }
                        />
                        <ExperienceBody experience={experience} />
                        <div className="w-full border-b border-primary" />
                        {location.pathname ===
                        `/adminExperience/preview/${id}` ? (
                          <div className="fixed gap-x-12 bottom-0 left-0 right-0 flex items-center w-screen py-6 font-semibold px-12 bg-white rounded-b rounded-[60px] border-t border-gray-200 shadow-md z-50">
                            <Link
                              className="flex items-center justify-center px-6 py-2 rounded-full lex bg-primary"
                              to={`/adminArea/${user}/approveExperiences`}
                            >
                              <img
                                src={indietro_white}
                                alt="indietro"
                                className="w-12"
                              />
                            </Link>
                            <button
                              className="w-full px-6 py-2 bg-gray-200 rounded-md"
                              onClick={() => setOpenModal(true)}
                            >
                              Suggerisci modifiche
                            </button>
                            <button
                              className="w-full px-6 py-2 text-white rounded-md bg-primary"
                              onClick={() => setOpenFeeModal(true)}
                            >
                              Approva
                            </button>
                            {openModal && (
                              <ModalSuggestEdit
                                setOpenModal={setOpenModal}
                                kind={"experience"}
                                entityId={id}
                              />
                            )}
                            {openFeeModal && (
                              <ModalSelectFee
                                setOpenModal={setOpenFeeModal}
                                kind={"experience"}
                                entityId={id}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="fixed gap-x-2 md:gap-x-12 bottom-0 left-0 right-0 flex w-screen py-4 md:py-6 font-semibold px-4 md:px-12 bg-white rounded-t-[25px] md:rounded-t-[60px] border-t border-gray-200 shadow-md z-50">
                            <Link
                              className="w-full px-6 py-2 text-sm text-center bg-gray-200 rounded-md md:text-base"
                              to={`/propose/${experience._id}/escobox`}
                            >
                              Torna a modificare
                            </Link>
                            {!experience.request_of_approval_made_at ? (
                              <button
                                className="w-full px-6 py-2 text-sm text-white rounded-md bg-primary md:text-base"
                                onClick={updateExperience}
                              >
                                Pubblica
                              </button>
                            ) : experience.edit_requested ? (
                              <button
                                className="w-full px-6 py-2 text-sm text-white rounded-md bg-primary md:text-base"
                                onClick={requestReview}
                              >
                                {isLoading ? (
                                  <UiduSpinner size="small" invertColor />
                                ) : (
                                  "Richiedi revisione"
                                )}
                              </button>
                            ) : (
                              <button
                                className="w-full px-6 py-2 text-sm text-white rounded-md bg-primary md:text-base"
                                onClick={() => navigate(`/creatorZone/${user}`)}
                              >
                                torna al profilo
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-full px-4 mb-24 gap-y-4 md:px-0">
                        <CardUser user={experience.user_id} />
                        <div className="flex flex-col p-4 bg-gray-200 rounded-lg shadow-md gap-y-4">
                          <div className="text-lg font-bold">
                            Prezzo per tipologia ospiti:{" "}
                            <span className="text-xs font-normal">
                              (con standard fee al {fees.experiences.fee}%)
                            </span>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="font-thin">Adulti:</div>
                            <div className="font-semibold text-center ">
                              {Math.ceil(
                                experience.price.adult / 100 +
                                  (fees.experiences.fee *
                                    experience.price.adult) /
                                    10000
                              ).toFixed(0) + ",00"}{" "}
                              €
                            </div>
                          </div>
                          {experience.price.child &&
                            experience.price.child !== -1 && (
                              <div className="flex flex-row justify-between">
                                <div className="font-thin">Bambini: </div>

                                <div className="font-semibold text-center ">
                                  {Math.ceil(
                                    experience.price.child / 100 +
                                      (fees.experiences.fee *
                                        experience.price.child) /
                                        10000
                                  ).toFixed(0) + ",00"}{" "}
                                  €
                                </div>
                              </div>
                            )}
                          {experience.price.group_price &&
                            experience.price.group_price !== -1 && (
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-col font-thin">
                                  <div>Gruppo privato</div>
                                  <div>
                                    max {experience.max_group_size} persone :
                                  </div>
                                </div>
                                <div className="font-semibold text-center ">
                                  {Math.ceil(
                                    experience.price.group_price / 100 +
                                      (fees.experiences.fee *
                                        experience.price.group_price) /
                                        10000
                                  ).toFixed(0) + ",00"}{" "}
                                  €
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </main>
    </div>
  );
}
