import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";

export default function ModalCreateCustomFee({
  setCreateCustomFee,
  setCustomFees,
  customFees,
}) {
  const [errors, setErrors] = useState([
    { id: "name", error: null },
    { id: "customFee", error: null },
  ]);

  const createCustomFee = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const currentErrors = [
      { id: "name", error: null },
      { id: "customFee", error: null },
    ];
    const name = document.getElementById("name").value;
    const customFee = document
      .getElementById("customFee")
      .value.replace(",", ".");

    if (name === "") {
      currentErrors[0].error = "Name is required";
    }
    if (customFee === "" || parseFloat(customFee) < 0) {
      currentErrors[1].error = "Invalid fee";
    }

    if (currentErrors[0].error || currentErrors[1].error) {
      setErrors(currentErrors);
      return;
    }

    const data = {
      name: name,
      fee: parseFloat(customFee),
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/customFeeCreate`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("custom fee", res.data);
        setCustomFees([
          ...customFees,
          res.data.custom[res.data.custom.length - 1],
        ]);
        setCreateCustomFee(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });

    setCreateCustomFee(false);
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => setCreateCustomFee(false)}
          className="mt-0 md:mt-10"
          width="large"
        >
          <ModalHeader>
            <h3 className="text-2xl">Crea fee personalizzata</h3>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCreateCustomFee(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody className="flex flex-col gap-y-2">
            <p className="font-semibold">Nome fee</p>
            <div className="relative flex flex-col w-full col-span-3 gap-y-4">
              <input
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="name"
                placeholder={`nome fee`}
              ></input>
              {errors.find((e) => e.id === "name")?.error && (
                <p className="-mt-2 text-sm text-red-400">
                  {errors.find((e) => e.id === "name")?.error}
                </p>
              )}
            </div>
            <p className="font-semibold">Fee</p>
            <div className="relative flex flex-col w-full col-span-4 gap-y-4">
              <CurrencyInput
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                placeholder="10%"
                id="customFee"
                min={0}
                suffix="%"
                decimalsLimit={2}
                decimalScale={2}
                allowNegativeValue={false}
              />
            </div>
            {errors.find((e) => e.id === "customFee")?.error && (
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "customFee")?.error}
              </p>
            )}
          </ModalBody>
          <ModalFooter className="flex justify-end gap-x-4">
            <button
              onClick={() => setCreateCustomFee(false)}
              className="px-6 py-2 bg-gray-300 rounded-md w-fit"
            >
              Annulla
            </button>
            <button
              onClick={() => createCustomFee()}
              className="px-6 py-2 text-white rounded-md bg-primary w-fit"
            >
              Crea
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
