import {
  faArrowDownWideShort,
  faArrowUpWideShort,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Bounce, ToastContainer } from "react-toastify";
import filtro from "../assets/icons/FILTRO.svg";
import Cover from "../components/Cover";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import PopUp from "../components/PopUp";
import SingleBox from "../components/SingleBox";

export default function EscoBoxes() {
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedExperienceTab") || "wine"
  );
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState(null);
  const [haveMaxPrice, setHaveMaxPrice] = useState(false);
  const [sliderValue, setSliderValue] = useState(null);
  const popUpRef = useRef(null);
  const [maxPriceBox, setMaxPriceBox] = useState(0);
  const [minPriceBox, setMinPriceBox] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  document.title = "Esco | EscoBox";

  const getEscoBoxes = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/escoboxesOfCategoryLimited`,
        {
          category: selectedTab,
          limit: 50,
        }
      )
      .then((res) => {
        setData(res.data);
        setInitialData(res.data);
        setMaxPriceBox(Math.max(...res.data.map((item) => item.price)));
        !haveMaxPrice &&
          setSliderValue(Math.max(...res.data.map((item) => item.price)));
        setMinPriceBox(Math.min(...res.data.map((item) => item.price)));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    setLoading(true);
    getEscoBoxes();
  }, [selectedTab]);

  const sortData = (data, order) => {
    if (order === "priceAsc") {
      return [...data].sort((a, b) => a.price - b.price);
    } else if (order === "priceDesc") {
      return [...data].sort((a, b) => b.price - a.price);
    } else {
      return data;
    }
  };

  useEffect(() => {
    let dataToSet = initialData;

    if (sliderValue && haveMaxPrice) {
      dataToSet = initialData.filter((item) => item.price <= sliderValue);
    }

    if (selectedLocation) {
      dataToSet = dataToSet.filter(
        (item) => item.city.toLowerCase() === selectedLocation.toLowerCase()
      );
    }

    setData(sortData(dataToSet, orderBy));
  }, [orderBy, sliderValue, haveMaxPrice, initialData, selectedLocation]);

  useEffect(() => {
    if (
      localStorage.getItem("selectedExperienceTab") === null ||
      localStorage.getItem("selectedExperienceTab") !== selectedTab
    ) {
      localStorage.setItem("selectedExperienceTab", selectedTab);
    }
  }, [selectedTab]);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            key={selectedTab}
            url={
              selectedTab === "wine"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_senior-couple-at-wine-tasting-2022-08-04-17-49-16-utc.mov"
                : selectedTab === "art"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_historical-columns-2022-01-18-23-34-52-utc.mov"
                : selectedTab === "outdoor"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/GX014697.mp4"
                : "https://escopublic.s3.eu-north-1.amazonaws.com/2_chef-and-students-smashing-garlic-at-cooking-maste-2022-08-05-01-52-56-utc.mov"
            }
            description={"ESPLORA, SCOPRI, GUSTA VIVI."}
            isVideo={true}
          />
        </div>
        <div className="relative">
          <PageBody
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setLoading={setLoading}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          >
            <div className="grid grid-cols-1 gap-8 mx-4 mt-12 md:mt-16 md:mx-0 md:grid-cols-2 lg:grid-cols-3">
              <div className="hidden lg:block">
                <Filters
                  minPrice={minPriceBox}
                  maxPrice={maxPriceBox}
                  sliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                  setHaveMaxPrice={setHaveMaxPrice}
                />
              </div>
              <div className="lg:col-span-2">
                <div className="sticky top-0 z-20 flex flex-col w-full px-1 py-2 bg-white md:py-6 gap-y-4 box-shadow-md">
                  <div className="flex items-center justify-between w-full px-4 my-4 md:my-0 md:px-0">
                    <h1 className="text-xl font-bold md:text-4xl text-secondary">
                      Le nostre proposte
                    </h1>
                    <div>
                      <button className="hidden" ref={popUpRef} />
                      <PopUp
                        iconStyle="noBackground"
                        icon={
                          <div className="px-3 py-2 mt-1 border rounded-full border-secondary hover:border-primary">
                            <img
                              src={filtro}
                              alt="filtra"
                              className="w-4 h-4"
                            />
                          </div>
                        }
                        items={
                          <>
                            <div className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] font-bold text-black">
                              <div>Ordina per:</div>
                            </div>
                            <div className="mx-4 border-b" />
                            <button
                              className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              onClick={() => {
                                if (orderBy === "priceAsc") {
                                  setOrderBy(null);
                                } else {
                                  setOrderBy("priceAsc");
                                }
                                popUpRef.current.click();
                              }}
                            >
                              <div className="w-8">
                                <FontAwesomeIcon icon={faArrowUpWideShort} />
                              </div>
                              <div>Prezzo crescente</div>
                            </button>
                            <div className="mx-4 border-b" />
                            <button
                              className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              onClick={() => {
                                if (orderBy === "priceDesc") {
                                  setOrderBy(null);
                                } else {
                                  setOrderBy("priceDesc");
                                }
                                popUpRef.current.click();
                              }}
                            >
                              <div className="w-8">
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                              </div>
                              <div>Prezzo decrescente</div>
                            </button>
                          </>
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-x-4">
                    {selectedLocation && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setSelectedLocation(null)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>{selectedLocation}</div>
                      </div>
                    )}
                    {haveMaxPrice && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setHaveMaxPrice(false)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>Prezzo massimo: €{sliderValue / 100}</div>
                      </div>
                    )}
                    {orderBy && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setOrderBy(null)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>
                          {orderBy === "priceAsc"
                            ? "Prezzo crescente"
                            : "Prezzo decrescente"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="flex items-center justify-center w-full h-96">
                    <Spinner size="large" />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-4 px-2 md:grid-cols-2 lg:grid-cols-3">
                    {data.map((item) => (
                      <SingleBox
                        item={item}
                        key={item.id}
                        selectedTab={selectedTab}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="fixed z-50">
              <ToastContainer
                containerId={`SingleBoxContainer`}
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Bounce}
                stacked
              />
            </div>
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
