import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useContext, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FeeContext } from "../App";

export default function ModalSelectFee({
  setOpenModal,
  kind,
  entityId,
  entities,
  setEntities,
}) {
  const animatedComponents = makeAnimated();
  const [selectedFee, setSelectedFee] = useState(
    kind === "structure" ? "structures" : "experiences"
  );
  const { fees } = useContext(FeeContext);
  console.log("fees", fees);

  const updateElement = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const user = localStorage.getItem("user");
    const fee_id =
      kind === "structure"
        ? selectedFee !== "structures"
          ? selectedFee
          : null
        : selectedFee !== "experiences"
        ? selectedFee
        : null;

    let data;

    if (!entities)
      data = {
        approved_at: new Date(),
        ...(fee_id !== null && { fee_id }),
      };
    else {
      data = {
        ...(fee_id !== null && { fee_id }),
      };
    }

    if (kind === "structure") {
      if (
        !fee_id &&
        entities.find((entity) => entity._id === entityId).fee_id
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/structure/removeFee/${entityId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (entities) {
              setEntities(
                entities.map((entity) =>
                  entity._id === entityId
                    ? { ...entity, fee_id: fee_id }
                    : entity
                )
              );
              setOpenModal(false);
            } else {
              window.location.href = `/adminArea/${user}`;
            }
          })
          .catch(() => {
            alert("Errore durante l'assegnazione della fee");
          });
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${entityId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (entities) {
              setEntities(
                entities.map((entity) =>
                  entity._id === entityId
                    ? { ...entity, fee_id: fee_id }
                    : entity
                )
              );
              setOpenModal(false);
            } else {
              window.location.href = `/adminArea/${user}`;
            }
          })
          .catch(() => {
            alert("Errore durante l'approvazione della struttura");
          });
      }
    } else if (kind === "experience") {
      if (
        !fee_id &&
        entities.find((entity) => entity._id === entityId).fee_id
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/experience/removeFee/${entityId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (entities) {
              setEntities(
                entities.map((entity) =>
                  entity._id === entityId
                    ? { ...entity, fee_id: fee_id }
                    : entity
                )
              );
              setOpenModal(false);
            } else {
              window.location.href = `/adminArea/${user}/approveExperiences`;
            }
          })
          .catch(() => {
            alert("Errore durante l'assegnazione della fee");
          });
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${entityId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (entities) {
              setEntities(
                entities.map((entity) =>
                  entity._id === entityId
                    ? { ...entity, fee_id: fee_id }
                    : entity
                )
              );
              setOpenModal(false);
            } else {
              window.location.href = `/adminArea/${user}/approveExperiences`;
            }
          })
          .catch(() => {
            alert("Errore durante l'approvazione dell'esperienza");
          });
      }
    }
    setOpenModal(false);
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
          className="mt-0 md:mt-10"
          shouldCloseOnOverlayClick={false}
          width="large"
        >
          <ModalHeader>
            <div className="flex items-center gap-x-4">
              <h3 className="text-2xl">Seleziona la Fee</h3>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col gap-y-2">
              <Select
                closeMenuOnSelect
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                defaultValue={
                  !entities
                    ? kind === "structure"
                      ? [{ value: "structures", label: "standard strutture" }]
                      : [{ value: "experiences", label: "standard esperienze" }]
                    : entities.find((entity) => entity._id === entityId)
                        .fee_id === "associations"
                    ? [
                        {
                          value: "associations",
                          label: "standard associazioni",
                        },
                      ]
                    : entities.find((entity) => entity._id === entityId).fee_id
                    ? fees.custom
                        .map((f) => ({
                          value: f._id,
                          label: f.name,
                        }))
                        .find(
                          (f) =>
                            f.value ===
                            entities.find((entity) => entity._id === entityId)
                              .fee_id
                        )
                    : kind === "structure"
                    ? [{ value: "structures", label: "standard strutture" }]
                    : [{ value: "experiences", label: "standard esperienze" }]
                }
                id="fee"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                options={
                  kind === "structure"
                    ? [
                        { value: "structures", label: "standard strutture" },
                        {
                          value: "associations",
                          label: "standard associazioni",
                        },
                        ...fees.custom.map((f) => ({
                          value: f._id,
                          label: f.name,
                        })),
                      ]
                    : [
                        { value: "experiences", label: "standard esperienze" },
                        {
                          value: "associations",
                          label: "standard associazioni",
                        },
                        ...fees.custom.map((f) => ({
                          value: f._id,
                          label: f.name,
                        })),
                      ]
                }
                onChange={(value) => {
                  setSelectedFee(value.value);
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
              <button
                className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Annulla
              </button>
              <button
                className="w-full h-10 text-sm text-white rounded-lg bg-primary md:w-52"
                onClick={() => updateElement()}
              >
                Seleziona fee
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
