import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import arrowBack from "../assets/icons/INDIETRO_WHITE.svg";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import NavbarCreator from "../components/NavbarCreator";

export default function EventKind() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [currentIdSelectedOption, setCurrentIdSelectedOption] = useState(null);
  const [userLogged, setUserLogged] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [loading, setLoading] = useState(true);
  const notify = () =>
    toast.error(
      "Devi verificare la tua email prima di poter creare un'esperienza",
      {
        toastId: Math.random().toString(),
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        containerId: "eventKindContainer",
      }
    );

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/loggedEmailVerified`)
        .then(async (res) => {
          console.log(res, "res motherfuckers");
          setUserLogged(res.data.logged);
          setEmailVerified(res.data.verified);
          if (id) {
            await axios
              .get(`${process.env.REACT_APP_SERVER_URL}/api/experience/${id}`)
              .then((res) => {
                console.log("experience", res.data.experience);
                setCurrentIdSelectedOption(res.data.experience.is_recurrent);
                setLoading(false);
              })
              .catch(() => {
                alert("Errore durante il caricamento dell'esperienza");
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setUserLogged(false);
          setLoading(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
      setLoading(false);
    }
  }

  const createExperience = async () => {
    if (emailVerified) {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem("user");

      const data = {
        user_id,
        is_recurrent: isRecurrent,
      };

      console.log(data, "data");

      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/experience`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const experience_id = res.data._id;
          console.log(experience_id, "experience_id", res);
          const dataUser = {
            has_experiences: true,
          };
          axios
            .put(
              `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user_id}`,
              dataUser,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              navigate(`/propose/${experience_id}`);
            })
            .catch(() => {
              alert("Errore durante la modifica dell'esperienza");
            });
        })
        .catch(() => {
          alert("Errore durante la creazione dell'esperienza");
        });
    } else {
      notify();
    }
  };

  const updateExperience = async () => {
    if (emailVerified) {
      const token = localStorage.getItem("token");

      const data = {
        is_recurrent: isRecurrent,
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          navigate(`/propose/${id}`);
        })
        .catch(() => {
          alert("Errore durante la modifica dell'esperienza");
        });
    } else {
      notify();
    }
  };

  useEffect(() => {
    if (isRecurrent) {
      if (!id) {
        createExperience();
      } else {
        updateExperience();
      }
    }
  }, [isRecurrent]);

  useEffect(() => {
    document.title = "Esco | Tipo di esperienza";
    Start();
  }, []);

  useEffect(() => {
    if (!loading && userLogged) {
      document
        .getElementById("page-top")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [loading]);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  return (
    <div id="page-top">
      <header>
        <NavbarCreator />
      </header>
      <main className="h-[calc(100vh-130px)]">
        <div className="mx-0 2xl:mx-8">
          <div className="relative flex flex-col items-center mx-4 md:mx-16 mt-32 max-w-screen-2xl 2xl:mx-auto h-[calc(100vh-268px)]">
            <button
              className="absolute top-0 left-0 mt-16 mr-4 rounded-full md:mt-4 bg-primary"
              onClick={() => navigate(-1)}
            >
              <img src={arrowBack} alt="Indietro" className="w-8 p-2" />
            </button>
            <h1 className="px-16 mt-12 mb-12 text-2xl text-center text-primary md:px-0">
              Di che tipo di esperienza si tratta?
            </h1>
            <div className="grid items-center justify-center w-full h-full grid-cols-1 md:grid-cols-2 gap-x-12">
              <button
                className={`h-24 px-10 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md relative ${
                  currentIdSelectedOption === false
                    ? "ring-2 ring-primary ring-offset-2 ring-offset-white"
                    : ""
                }`}
                onClick={() => {
                  setIsRecurrent(false);
                  if (!id) {
                    createExperience();
                  } else {
                    updateExperience();
                  }
                }}
              >
                <h1 className="text-2xl font-semibold text-center">
                  Evento singolo
                </h1>
                {currentIdSelectedOption === false && (
                  <div className="absolute rounded-full top-2 right-2">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-2xl text-primary"
                    />
                  </div>
                )}
              </button>
              <button
                className={`h-24 px-10 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md relative ${
                  currentIdSelectedOption
                    ? "ring-2 ring-primary ring-offset-2 ring-offset-white"
                    : ""
                }`}
                onClick={() => {
                  setIsRecurrent(true);
                }}
              >
                <h1 className="text-2xl font-semibold text-center">
                  Evento ricorrente
                </h1>
                {currentIdSelectedOption && (
                  <div className="absolute rounded-full top-2 right-2">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-2xl text-primary"
                    />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          containerId={`eventKindContainer`}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </main>
    </div>
  );
}
