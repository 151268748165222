import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoadingSpinnerPage from "./components/LoadingSpinnerPage";
import "./index.css";
import AdminArea from "./pages/AdminArea";
import Article from "./pages/Article";
import BecomePartner from "./pages/BecomePartner";
import BecomePartnerExperience from "./pages/BecomePartnerExperience";
import BecomePartnerStructure from "./pages/BecomePartnerStructure";
import Cart from "./pages/Cart";
import Community from "./pages/Community";
import ConfirmEmail from "./pages/ConfirmEmail";
import ContactUs from "./pages/ContactUs";
import Counter from "./pages/Counter";
import CreatorZone from "./pages/CreatorZone";
import DiscoverLocation from "./pages/DiscoverLocation";
import ErrorPage from "./pages/ErrorPage";
import EscoBox from "./pages/EscoBox";
import EscoBoxes from "./pages/EscoBoxes";
import EventKind from "./pages/EventKind";
import Experience from "./pages/Experience";
import ExperiencePreview from "./pages/ExperiencePreview";
import Experiences from "./pages/Experiences";
import Faq from "./pages/Faq";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Partner from "./pages/Partner";
import PartnerZone from "./pages/PartnerZone";
import Payment from "./pages/Payment";
import Profile from "./pages/Profile";
import Propose from "./pages/Propose";
import ProposeStructure from "./pages/ProposeStructure";
import ResetPassword from "./pages/ResetPassword";
import Structure from "./pages/Structure";
import StructurePreview from "./pages/StructurePreview";
import Structures from "./pages/Structures";

export const FavouriteContext = createContext();
export const FeeContext = createContext();
export const ElementsCartContext = createContext();
export const UserLoggedContext = createContext();
export const UserContext = createContext();
export const AreaContext = createContext();

export default function App() {
  const [favourite, setFavourite] = useState(null);
  const [fees, setFees] = useState(null);
  const [done, setDone] = useState(false);
  const [elements, setElements] = useState([]);
  const [userLogged, setUserLogged] = useState(null);
  const [fromApp, setFromApp] = useState(false);
  const [user, setUser] = useState({});
  const [area, setArea] = useState("explorer");

  const checkUserAuthorized = () => {
    const isEscoAuthorizedUser = localStorage.getItem("isEscoAuthorizedUser");

    if (isEscoAuthorizedUser) {
      const item = JSON.parse(isEscoAuthorizedUser);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem("isEscoAuthorizedUser");
        return false;
      } else {
        setTimeout(() => {
          window.location.reload();
        }, item.expiry - now.getTime());
        return true;
      }
    } else {
      return false;
    }
  };

  const [userAuthorized, setUserAuthorized] = useState(
    // checkUserAuthorized() ? true : false
    true
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/experiences",
      element: <Experiences />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/structures",
      element: <Structures />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/experiences/:id",
      element: <Experience />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/structures/:id",
      element: <Structure />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/community",
      element: <Community />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/escoboxes",
      element: <EscoBoxes />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/escoboxes/:id",
      element: <EscoBox />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proposeExperience",
      element: <BecomePartnerExperience />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proposeStructure",
      element: <BecomePartnerStructure />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/becomePartner",
      element: <BecomePartner />,
      errorElement: <ErrorPage />,
    },
    { path: "/login", element: <Login />, errorElement: <ErrorPage /> },
    {
      path: "/propose/:id/:tab?",
      element: <Propose />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proposeStructure/:id/:tab?",
      element: <ProposeStructure />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile/:id/:tab?",
      element: <Profile />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/experience/preview/:id",
      element: <ExperiencePreview />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/adminExperience/preview/:id",
      element: <ExperiencePreview />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/structure/preview/:id",
      element: <StructurePreview />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/adminStructure/preview/:id",
      element: <StructurePreview />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/creatorZone/:id/:tab?",
      element: <CreatorZone />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/partner/:id/:tab?",
      element: <Partner />,
      errorElement: <ErrorPage />,
    },
    { path: "/eventKind", element: <EventKind />, errorElement: <ErrorPage /> },
    {
      path: "/editEventKind/:id",
      element: <EventKind />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/adminArea/:id/:tab?",
      element: <AdminArea />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/cart",
      element: <Cart />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payment/:clientCartSecret",
      element: <Payment />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/article/:id",
      element: <Article />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/partnerZone/:id/:tab?",
      element: <PartnerZone />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/faq",
      element: <Faq />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/contactUs",
      element: <ContactUs />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/discover/:id",
      element: <DiscoverLocation />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/resetPassword/:token",
      element: <ResetPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/emailVerification/:token",
      element: <ConfirmEmail />,
      errorElement: <ErrorPage />,
    },
  ]);

  const routerUnauthorized = createBrowserRouter([
    {
      path: "*",
      element: <Counter />,
      errorElement: <ErrorPage />,
    },
  ]);

  const getFavouriteAndCart = async () => {
    const user_id = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then(async (res) => {
          setUserLogged(true);
          if (user_id) {
            await axios
              .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${user_id}`)
              .then(async (res) => {
                setUser(res.data);
                await axios
                  .get(
                    `${process.env.REACT_APP_SERVER_URL}/api/favourite/${user_id}`
                  )
                  .then(async (res) => {
                    setFavourite(res.data);
                    await axios
                      .get(
                        `${
                          process.env.REACT_APP_SERVER_URL
                        }/api/cart/${localStorage.getItem("user")}`
                      )
                      .then((res) => {
                        if (!res.data.message) {
                          setElements(res.data.elements);
                        }
                        setDone(true);
                      })
                      .catch((err) => {
                        console.log(err);
                        setDone(true);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    setDone(true);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setDone(true);
          }
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
      setDone(true);
    }
  };

  const getFee = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/fee`)
      .then((res) => {
        console.log("fees", res.data);
        setFees(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getFee();
    getFavouriteAndCart();
  }, []);

  useEffect(() => {
    const params = window.location.search;
    console.log("params", params);
    if (params === "?fromApp=true") {
      setFromApp(true);
    }
  }, []);

  return (
    <React.StrictMode>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_ANDROID}
      >
        {done ? (
          <FavouriteContext.Provider value={{ favourite, setFavourite }}>
            <FeeContext.Provider value={{ fees, setFees }}>
              <ElementsCartContext.Provider value={{ elements, setElements }}>
                <UserLoggedContext.Provider
                  value={{ userLogged, setUserLogged }}
                >
                  <UserContext.Provider value={{ user, setUser, fromApp }}>
                    <AreaContext.Provider value={{ area, setArea }}>
                      <RouterProvider
                        router={userAuthorized ? router : routerUnauthorized}
                      />
                    </AreaContext.Provider>
                  </UserContext.Provider>
                </UserLoggedContext.Provider>
              </ElementsCartContext.Provider>
            </FeeContext.Provider>
          </FavouriteContext.Provider>
        ) : (
          <LoadingSpinnerPage />
        )}
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
}
