import {
  faArrowDownWideShort,
  faArrowUpWideShort,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Bounce, ToastContainer } from "react-toastify";
import { FeeContext } from "../App";
import Cover from "../components/Cover";
import Filters from "../components/Filters";
import Footer from "../components/Footer";

import filtro from "../assets/icons/FILTRO.svg";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import PopUp from "../components/PopUp";
import SingleStructure from "../components/SingleStructure";
import { LocationValleys } from "../components/utils/LocationValleys";

export default function Structures() {
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedStructureTab") || "agriturismo"
  );
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const popUpRef = useRef(null);
  const [maxPriceStructure, setMaxPriceStructure] = useState(0);
  const [minPriceStructure, setMinPriceStructure] = useState(0);
  const [sliderValue, setSliderValue] = useState(null);
  const [haveMaxPrice, setHaveMaxPrice] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { fees } = useContext(FeeContext);
  document.title = "Esco | Alloggi";

  const structureOfCategory = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/structuresApprovedOfCategoryLimited`,
        {
          kind: selectedTab,
          limit: 50,
        }
      )
      .then((res) => {
        console.log(res.data, "res");
        setInitialData(res.data);
        setData(res.data);
        setMaxPriceStructure(
          Math.max(...res.data.map((item) => item.minRoomPrice))
        );
        !haveMaxPrice &&
          setSliderValue(
            Math.max(...res.data.map((item) => item.minRoomPrice))
          );
        setMinPriceStructure(
          Math.min(...res.data.map((item) => item.minRoomPrice))
        );
        if (!selectedLocation && !haveMaxPrice && !orderBy) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    setLoading(true);
    structureOfCategory();
  }, [selectedTab]);

  const sortData = (data, order) => {
    if (order === "priceAsc") {
      return [...data].sort((a, b) => a.minRoomPrice - b.minRoomPrice);
    } else if (order === "priceDesc") {
      return [...data].sort((a, b) => b.minRoomPrice - a.minRoomPrice);
    } else {
      return data;
    }
  };

  useEffect(() => {
    let dataToSet = initialData;

    if (sliderValue && haveMaxPrice) {
      dataToSet = initialData.filter(
        (item) =>
          item.minRoomPrice + (fees.structures.fee * item.minRoomPrice) / 100 <=
          sliderValue[0]
      );
    }

    if (selectedLocation) {
      const villages = LocationValleys.filter(
        (valley) => valley.name === selectedLocation
      )[0].villages;
      dataToSet = dataToSet.filter((item) => {
        return villages.some(
          (village) =>
            village.toLowerCase() ===
              (item.address.municipality || "").toLowerCase() ||
            village.toLowerCase() ===
              (item.address.locality || "").toLowerCase()
        );
      });
    }

    setData(sortData(dataToSet, orderBy));
    setLoading(false);
  }, [orderBy, sliderValue, haveMaxPrice, selectedLocation, initialData]);

  useEffect(() => {
    if (
      localStorage.getItem("selectedStructureTab") === null ||
      localStorage.getItem("selectedStructureTab") !== selectedTab
    ) {
      localStorage.setItem("selectedStructureTab", selectedTab);
    }
  }, [selectedTab]);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            url={
              "https://escopublic.s3.eu-north-1.amazonaws.com/coverAlloggi.jpeg"
            }
            isVideo={false}
            description={"GLI ALLOGGI PIÙ APPREZZATI"}
          />
        </div>
        <div className="relative">
          <PageBody
            isStructure={true}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          >
            {/* <div className="grid grid-cols-1 gap-8 mt-32 mb-16 md:grid-cols-2 lg:grid-cols-3">
              <div></div>
              <div className="lg:col-span-2">
                <h1 className="text-3xl md:text-4xl text-secondary">
                  Le nostre esperienze
                </h1>
              </div>
            </div> */}
            <div className="grid grid-cols-1 gap-8 mx-4 mt-12 md:mt-16 md:mx-0 md:grid-cols-2 lg:grid-cols-3">
              <div className="hidden md:block">
                <Filters
                  minPrice={minPriceStructure}
                  maxPrice={maxPriceStructure}
                  sliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                  setHaveMaxPrice={setHaveMaxPrice}
                />
              </div>
              <div className="lg:col-span-2">
                <div className="sticky top-0 z-10 flex flex-col w-full px-1 py-2 bg-white md:py-6 gap-y-4 box-shadow-md">
                  <div className="flex items-center justify-between w-full px-4 my-4 md:my-0 md:px-0">
                    <h1 className="text-xl font-bold md:text-4xl text-secondary">
                      I nostri alloggi
                    </h1>
                    <div>
                      <button className="hidden" ref={popUpRef} />
                      <PopUp
                        iconStyle="noBackground"
                        icon={
                          <div className="px-3 py-2 mt-1 border rounded-full border-secondary hover:border-primary">
                            <img
                              src={filtro}
                              alt="filtra"
                              className="w-4 h-4"
                            />
                          </div>
                        }
                        items={
                          <>
                            <div className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] font-bold text-black">
                              <div>Ordina per:</div>
                            </div>
                            <div className="mx-4 border-b" />
                            <button
                              className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              onClick={() => {
                                if (orderBy === "priceAsc") {
                                  setOrderBy(null);
                                } else {
                                  setOrderBy("priceAsc");
                                }
                                popUpRef.current.click();
                              }}
                            >
                              <div className="w-8">
                                <FontAwesomeIcon icon={faArrowUpWideShort} />
                              </div>
                              <div>Prezzo crescente</div>
                            </button>
                            <div className="mx-4 border-b" />
                            <button
                              className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              onClick={() => {
                                if (orderBy === "priceDesc") {
                                  setOrderBy(null);
                                } else {
                                  setOrderBy("priceDesc");
                                }
                                popUpRef.current.click();
                              }}
                            >
                              <div className="w-8">
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                              </div>
                              <div>Prezzo decrescente</div>
                            </button>
                          </>
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-x-4">
                    {selectedLocation && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setSelectedLocation(null)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>{selectedLocation}</div>
                      </div>
                    )}
                    {haveMaxPrice && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setHaveMaxPrice(false)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>Prezzo massimo: €{sliderValue / 100}</div>
                      </div>
                    )}
                    {orderBy && (
                      <div className="flex items-center justify-center px-4 py-1 text-xs text-white rounded-md gap-x-2 bg-primary ring-2 ring-offset-2 ring-secondary">
                        <button
                          onClick={() => setOrderBy(null)}
                          className="cursor-pointer "
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="text-white"
                          />
                        </button>
                        <div>
                          {orderBy === "priceAsc"
                            ? "Prezzo crescente"
                            : "Prezzo decrescente"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="flex items-center justify-center w-full h-96">
                    <Spinner size="large" />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-4 px-2 md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3">
                    {data.length === 0 && (
                      <div className="w-full col-span-3 text-2xl text-gray-500">
                        Nessuna struttura disponibile al momento
                      </div>
                    )}
                    {data.map((item) => (
                      <SingleStructure item={item} key={item.id} />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="fixed z-50">
              <ToastContainer
                containerId={`SingleStructureContainer`}
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Bounce}
                stacked
              />
            </div>
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
