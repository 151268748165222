import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import calendar from "../../assets/icons/CALENDARIO.svg";
import geotag from "../../assets/icons/GEOTAG.svg";
import stella from "../../assets/icons/STELLA.svg";

export default function ExperienceSubtitle({ experience }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-wrap gap-4 text-lg">
      {!experience.is_recurrent && (
        <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
          <img src={calendar} alt="calendar" className="w-[18px] md:w-6" />
          <div className="text-base font-semibold md:text-lg text-escoBlack">
            {dayjs(experience.data_info.start_date).format("DD MMM YY")}
          </div>
        </div>
      )}
      <div className="flex items-center mr-6 md:mr-12 gap-x-2 md:gap-x-4">
        <img src={geotag} alt="calendar" className="w-[18px] md:w-6" />
        <div className="text-base font-semibold md:text-lg text-escoBlack">
          {experience.address
            ? experience.address.municipality
              ? experience.address.municipality
              : experience.address.village
            : "Pertica Bassa"}
        </div>
      </div>
      <div className="flex items-center mr-6 gap-x-2 md:gap-x-4 md:mr-0">
        <img src={stella} alt="calendar" className="w-[18px] md:w-6" />
        <div className="text-base md:text-lg text-escoBlack">
          <span className="font-semibold">
            {experience.reviews ? averageReviews(experience.reviews) : "0"}
          </span>
          /5{" "}
          {windowWidth < 768 &&
            `(${experience.reviews ? experience.reviews.length : 0})`}
        </div>
      </div>
      <div className="items-center gap-x-1.5 text-base text-primary hidden md:flex">
        <div>
          {experience.reviews ? experience.reviews.length : 0} recensioni
        </div>
      </div>
    </div>
  );
}
